<template>
  <div v-if="data.featuredCaseStudies.length" id="featured-casestudies">
    <carousel-helper :options="options" :custom-nav="!isXs" :custom-pagination="isXs">
      <swiper-slide v-for="(item, index) in data.featuredCaseStudies" :key="index" class="slide">
        <div class="outer">
          <div class="wrapper grid gap-30">
            <p class="white" :class="[isXs ? 'v-small' : 'small']"><span class="strong">featured</span> / case study</p>
            <div class="inner grid gap-20">
              <h2 class="white">{{item.title}}</h2>
              <p class="white">{{item.introduction}}</p>
            </div>
            <router-link :to="$buildInternalLink(item)" class="white">
              <inline-svg :src="$getImageSrc('arrow-long.svg')"></inline-svg>Read more
            </router-link>
          </div>
        </div>
      </swiper-slide>
    </carousel>
  </div>
</template>

<script>
  import CarouselHelper from '~/components/CarouselHelper.vue'
  import { SwiperSlide } from 'vue-awesome-swiper'

  export default {
    components: { CarouselHelper, SwiperSlide },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        options: {
          slidesPerView: 1,
          spaceBetween: 0,
          speed: 600,
          loop: true,
          slidesPerGroup: 1,
          grabCursor: true,
        }
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      }
    },
    methods: {
      getRoute(index){
        let slug = this.data.featuredCaseStudies[index].slug
        return{
          name: 'ourResearch.ourResearchCaseStudies.caseStudiesChildren',
          slug: slug
        }
      }
    }
  }
</script>