<template>
  <div v-if="data" id="share">
    <div class="share-wrapper" @click="showIcons = !showIcons" :class="{'active' : showIcons}">
      <h4>share <inline-svg :src="$getImageSrc('share.svg')"></inline-svg></h4>
    </div>

    <div v-if="showIcons" class="icons-wrapper">
      <a :href="facebook">
        <inline-svg :src="$getImageSrc('share-facebook.svg')"></inline-svg>
      </a>
      <a :href="twitter">
        <inline-svg :src="$getImageSrc('share-twitter.svg')"></inline-svg>
      </a>
      <a :href="linkedIn">
        <inline-svg :src="$getImageSrc('share-linkedin.svg')"></inline-svg>
      </a>
      <a :href="email">
        <inline-svg :src="$getImageSrc('share-email.svg')"></inline-svg>
      </a>
    </div>
  </div>
</template>


<script>

  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
        showIcons: false
      }
    },
    computed: {
      fullPath() {
        return window.location.href
      },
      facebook() {
        return `https://www.facebook.com/sharer/sharer.php?u=${this.fullPath}`
      },
      twitter() {
        return `https://twitter.com/intent/tweet?text=${this.fullPath}`
      },
      linkedIn() {
        return `https://www.linkedin.com/shareArticle?mini=true&url=${this.fullPath}&title=${this.data.fullTitle}&summary=${this.data.articleIntroduction}`
      },
      email() {
        return `mailto:?subject=${this.$store.state.title} : ${this.data.title}&body=${this.fullPath}`
      }
    }
  }
</script>