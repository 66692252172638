<template>
  <content-wrapper id="home" class="slim" v-if="data.result">
    
    <template v-slot:header>
      <div class="hero-wrapper is-cover">
        <template>
          <image-helper :data="data.entry.heroImage"></image-helper>
        </template>
        <div class="container cw-inner">
          <h1>{{data.entry.heroTitle}}</h1>
          <h4 v-if="!isXs" @click="$scrollWithOffset('.introduction')">Scroll for more</h4>
        </div>
      </div>
    </template>

    

    <div class="introduction">
      <h2>{{data.entry.introduction}}</h2>
    </div>

    <template v-slot:footer >
      <featured-pages :data="data.entry"></featured-pages>
      <featured-casestudies :data="data.entry"></featured-casestudies>
      <featured-news-events :data="data.entry"></featured-news-events>
    </template>

  </content-wrapper>
</template>

<script>
  import ContentWrapper from '~/components/ContentWrapper.vue'
  import FeaturedPages from '~/components/home/FeaturedPages.vue'
  import FeaturedCasestudies from '~/components/home/FeaturedCasestudies.vue'
  import FeaturedNewsEvents from '~/components/home/FeaturedNewsEvents.vue'

  import gql from 'graphql-tag'
  import { FRAGMENT_HOME_FIELDS } from '~/gqlFragments.js';

  export default {
    components : { ContentWrapper, FeaturedPages, FeaturedCasestudies, FeaturedNewsEvents },
    data() {
      return {
        data: {
          result: false,
          entry: Object,
        }
      }
    },
    apollo: {
      entry: {
        query: gql`
          query variables($section: [String!]){
            entry(section: $section) {
                id,
                title,
                ...on home_home_Entry{
                heroTitle,
                heroImage{
                  url,
                  title,
                  width,
                  height,
                  focalPoint,
                  mimeType,
                  s400: url @transform(width: 400),
                  s800: url @transform(width: 800),
                  s1200: url @transform(width: 1200),
                  s1600: url @transform(width: 1600),
                  s2000: url @transform(width: 2000),
                  s2400: url @transform(width: 2400),
                  s2800: url @transform(width: 2800),
                  s3200: url @transform(width: 3200)
                },
                introduction,
                featuredPages{
                  ...featuredPagesModule
                },
                featuredCaseStudies{
                  ... on caseStudies_caseStudies_Entry{
                  id, title, introduction: articleIntroduction, slug, sectionHandle
                  }
                  },
                featuredNewsEvents{
                  ...on updates_updates_Entry{
                    __typename,
                    id, title, sectionHandle, slug,
                    publishedDate @formatDateTime (format: "d M Y"),
                    heroImage{
                      url,
                      title,width,
                      height,
                      focalPoint,
                      mimeType,
                      s400: url @transform(width: 400),
                      s800: url @transform(width: 800),
                      s1200: url @transform(width: 1200),
                      s1600: url @transform(width: 1600),
                      s2000: url @transform(width: 2000),
                      s2400: url @transform(width: 2400),
                      s2800: url @transform(width: 2800),
                      s3200: url @transform(width: 3200)
                    }
                  },
                  ...on events_events_Entry{
                    __typename,
                    id, title, eventDate @formatDateTime (format: "d M Y"), eventTime, sectionHandle, slug
                    heroImage{
                      url,
                      title,width,
                      height,
                      focalPoint,
                      mimeType,
                      s400: url @transform(width: 400),
                      s800: url @transform(width: 800),
                      s1200: url @transform(width: 1200),
                      s1600: url @transform(width: 1600),
                      s2000: url @transform(width: 2000),
                      s2400: url @transform(width: 2400),
                      s2800: url @transform(width: 2800),
                      s3200: url @transform(width: 3200)
                    }
                  }
                }
              }
            }
          }
          ${FRAGMENT_HOME_FIELDS}
          `,
          variables() {
            return {
              section: 'home'
            }
          },
          result ({ data, loading, networkStatus }) {
            if (data.entry !== null){ 
              this.$store.commit('setTitle',data.entry.title)
              this.data.entry = data.entry
              this.$store.commit('setLoaded',true)
              this.data.result = true
              if (this.$store.state.graphQLDebug){
                console.log('Full graphQL entry data:')
                console.log(data.entry)
              }
            }
            else{
              this.$router.push({ name: 'errorServer' })
            }
          }
        }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
    }
  }
</script>