<template>

  <div class="inline-logos">
    <div class="title-wrapper" v-if="data.title || data.byline">
      <h3 v-if="data.title">{{data.title}}</h3>
      <p v-if="data.byline">{{data.byline}}</p>
    </div>
    
    <div class="img-wrapper">
      <image-helper v-for="(logo,index) in data.logos" :data="[logo]"></image-helper>
    </div> 
  </div>
</template>


<script>

  export default {
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    data(){
      return {
      }
    },
    computed: {
    },
  }
</script>