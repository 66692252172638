<template>
  <content-wrapper id="contact" class="reverse small-gap" v-if="data.result">
    
    <template v-slot:header>
      <hero-image :data="data.entry.heroImage"></hero-image>
    </template>

    <div class="grid gap-40">
      <h1 v-if="isXs">Contact</h1>
      <div class="grid gap-20">
        <div v-for="contact in data.entry.contact" class="contact">
          <p v-if="contact.contactTitle" class="strong">{{contact.contactTitle}}</p>
          <p v-if="contact.address1">{{contact.address1}}</p>
          <p v-if="contact.address2">{{contact.address2}}</p>
          <p v-if="contact.region || contact.country">{{regionCountry(contact)}}</p>
          <template v-for="postal in contact.postal">
            <p v-if="postal.poBox" class="postal">{{postal.poBox}}</p>
            <p v-if="postal.postalAddress1">{{postal.postalAddress1}}</p>
            <p v-if="postal.region || postal.country">{{regionCountry(postal)}}</p>
          </template>
          <p v-if="contact.contactEmail">Email: <a :href="'mailto:' + contact.contactEmail">{{contact.contactEmail}}</a></p>
          <p v-if="contact.phoneNumber">Phone: <a :href="'tel:' + contact.phoneNumber">{{contact.phoneNumber}}</a></p>
        </div>
      </div>

      <div class="map-wrapper">
        <map-helper :markers="markers" :zoom="13"></map-helper>
      </div>
    </div>

    <p class="big strong l-blue form-prompt">To find out more about research at Lincoln, as a student, research organization, commercial enterprise, government body, or in another capacity, please use the following form.<img src="/assets/img/arrow.svg"></p>

    <template v-slot:secondary>
      <h1 v-if="!isXs">Contact</h1>
    </template>

    <template v-slot:footer>
      <contact-form :data="data.entry.contactForm[0]">
        <img src="/assets/img/arrow.svg">
      </contact-form>
    </template>

  </content-wrapper>
</template>


<script>
  import ContentWrapper from '~/components/ContentWrapper.vue'
  import HeroImage from '~/components/HeroImage.vue'
  import MapHelper from '~/components/MapHelper.vue'
  import ContactForm from '~/components/ContactForm.vue'
  import gql from 'graphql-tag'

  export default {
    components: { ContentWrapper, HeroImage, MapHelper, ContactForm },
    data(){
      return {
        data: {
          result: false,
          entry: Object,
        },
      }
    },
    apollo: {
      entry: {
        query: gql`
            query variables($section: [String!]) {
              entry(section: $section) {
                id
                title
                ... on contactIndex_contactIndex_Entry {
                  mapGPSLatitude
                  mapGPSLongitude
                  contact {
                    ... on contact_Contact_BlockType {
                      id
                      address1
                      address2
                      archived
                      contactEmail
                      contactTitle
                      region
                      country
                      postal {
                        ... on postal_BlockType {
                          id
                          poBox
                          postalAddress1
                          postalAddress2
                          country: postalCountry
                          region: postalRegion
                        }
                      }
                    }
                  }
                  heroImage {
                    url,title,
                    width,
                    height,
                    focalPoint,
                    mimeType,
                    s400: url @transform(width: 400),
                    s800: url @transform(width: 800),
                    s1200: url @transform(width: 1200),
                    s1600: url @transform(width: 1600),
                    s2000: url @transform(width: 2000),
                    s2400: url @transform(width: 2400),
                    s2800: url @transform(width: 2800),
                    s3200: url @transform(width: 3200)
                  }
                  contactForm {
                    ...on contactForm_ContactForm_BlockType{
                      id
                      formByline
                    }
                  }
                }
              }
            }
          `,
          variables() {
            return {
              section: 'contactIndex'
            }
          },
          result ({ data, loading, networkStatus }) {
            if (data.entry !== null){ 
              // initial debug work - will remove soon
              // console.log(data.entry.contentModules)
              this.$store.commit('setPageID',this.data.entry.id)
              this.$store.commit('setTitle',data.entry.title)
              
              this.data.entry = data.entry

              // pass craftresult to store and set craftLoaded state
              this.$store.commit('setCraftPayload',data.entry)
              this.$store.commit('setPageID',data.entry.id)
              this.$store.commit('setCraftLoaded',true)
              this.$store.commit('setLoaded',true)
              this.data.result = true

              // pass through contactform data to component is same way orderable components are handled
              this.data.contactForm = this.data.entry.contactForm[0]

              if (this.$store.state.graphQLDebug){
              console.log('Full graphQL entry data:')
                console.log(data.entry)
              }
            }
            else{
              this.$router.push({ name: 'errorServer' })
            }
          }
        }
    },
    computed: {
      markers() {
        let markers = [];

        // duplicate block to add additional markers, or build a method if many markers
        markers.push({
          position: {
            lat: parseFloat(this.data.entry.mapGPSLatitude), 
            lng: parseFloat(this.data.entry.mapGPSLongitude)
          },
        }) 

        return markers;
      },
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      checkLoaded(){
        return this.data.result
      }
    },
    methods: {
      regionCountry(contact) {
        let result = []
        result.push(contact.region)
        result.push(contact.country)
        return result.join(', ')
      },
      
    },
    created() {
      this.$store.commit('setPageID',0)
      this.$store.commit('setCraftPayload',this.data)
    }
  }
</script>