<template>
  <div id="error500" class="container">
    <div class="grid gap-10">
      <h1 class="d-blue">Sorry!</h1>
      <h2>We are having some temporary problems with our website right now, please check back later.</h2>      
    </div>
  </div>
</template>


<script>

  export default {
    data(){
      return {
      }
    },
    computed: {
    }
  }
</script>