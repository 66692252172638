
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueScrollTo from 'vue-scrollto'
import VueGtag from 'vue-gtag'
import Meta from 'vue-meta'
import * as VueGoogleMaps from 'vue2-google-maps'
import Store from './store.js'
import Router from './router.js'
import Helpers from './helpers.js'
import {InlineSvgPlugin} from 'vue-inline-svg'
import VScrollLock from 'v-scroll-lock'
import App from './components/App.vue'
import Stringify from 'vue-stringify'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import schema from './schema.json'
import VueApollo from 'vue-apollo'
import ImageHelper from './components/ImageHelper.vue'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(VueScrollTo)
Vue.use(Helpers)
Vue.use(Meta)
Vue.use(VScrollLock)
Vue.use(InlineSvgPlugin)
Vue.use(VueApollo)

Vue.component('image-helper', ImageHelper)


// axios.defaults.headers.common['Accept'] = '*/*';
// axios.defaults.headers.common['Content-Type'] = 'a';
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.baseURL = process.env.BASE_URL;
let m = document.location.href.match(/\btoken=([^&]+)/);
let token = m ? m[1] : '';
// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here    
  uri: `${process.env.BASE_URL}/api?token=${token}`
})

// Retrieve schema map
const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: schema
})

// Cache implementation
const cache = new InMemoryCache({fragmentMatcher})

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
})



Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyArV7l3XKmxYqgfII4tT-zWmxDF8ZxqEhw',
	},
})

const router = new VueRouter(Router)
const store = new Vuex.Store(Store);

Vue.use(VueGtag, {
  config: { id: "GTM-56F8LJ" }
}, router);

router.beforeEach((to, from, next) => {

  store.commit('setPopstate', false)
  store.commit('setLastRoute', from)

  next();
});

new Vue({
	router,
	store,
	apolloProvider,
	render: createElement => createElement(App)
}).$mount('#app');