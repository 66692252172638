<template>
  <div v-if="data.length" id="downloads" class="grid gap-10">
    <div class="col">
      <h4>Downloads</h4>
      <a v-for="download,index in data" :href="downloadItem(index).url" target="_blank">
        <ul>
          <li>{{download.title}}</li>
          <li class="detail"><span class="v-strong">{{downloadItem(index).kind}}</span> {{getSize(downloadItem(index).size)}}</li>
        </ul>
        <img src="/assets/img/arrow.svg">
      </a>
    </div>
  </div>
</template>


<script>
  export default {
    props: {
      data: {
        type: Array,
        required: true,
        
      }
    },
    data(){
      return {
      }
    },
    methods: {
      getSize(bytes) {
        if (bytes === 0) return '0 Bytes';

        const decimals = 0;
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const int = Math.floor(Math.log(bytes) / Math.log(k));
        let data = parseFloat((bytes / Math.pow(k, int)).toFixed(dm)) + ' ' + sizes[int]
        return data
      },
      downloadItem(index){
        return this.data[index].downloadFile[0]
      }
    }
  }
</script>