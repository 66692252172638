<template>
  <div id="breadcrumb" v-if="showBreadcrumb">
    <div class="container">
      <router-link :to="{name: 'home'}">Home</router-link>
      <router-link v-for="item in breadcrumbArray" :to="{name: item.name}">{{item.meta['title']}}</router-link>
      <a v-if="dynamicParent()" href="#">{{getParentTitle()}}</a>
      <a v-if="dynamicPage()" href="#">{{getTitle()}}</a>
    </div>
  </div>
</template>


<script>

  export default {
    data(){
      return {
        breadcrumbArray: []
      }
    },
    computed: {
      showBreadcrumb() {
        return this.$store.state.breakpoint !== 'xs' && this.$route.name !== 'searchIndex' && this.$route.name !== 'home'
      },
    },
    methods: {
      setBreadcrumb() {
        this.$store.commit('setBreadcrumb', this.showBreadcrumb)
      },
      getTitle(){
        return(this.$store.state.title !== 'Lincoln University Research') ? this.$store.state.title : ''
      },
      getParentTitle(){
        return this.$store.state.parentTitle 
      },
      dynamicPage(){
        let routeParts = this.$route.name.split('.') 
        if (routeParts.length > 2 || typeof(this.$route.params.slug) !== 'undefined' && this.$route.params.slug !== 'student-profiles')
          return true;
        else
          return false
      },
      dynamicParent(){
        if (this.$store.state.parentTitle !== '')
          return true;
      },
      interpretBreadCrumb(){
        // L1 + L2 check - current page will be collected via dynamicPage()
        // console.log('Current Route:')
        // console.log(this.$route)
        let routeParts = this.$route.name.split('.') 
        let builtUp = ''
        this.breadcrumbArray = [];
        routeParts.forEach(currentRoute=>{
            if (builtUp !== '')
              builtUp = builtUp + '.' + currentRoute
            else
              builtUp = currentRoute
            // console.log('checking ' + builtUp);
            this.$router.options.routes.forEach(route => {
                if (builtUp === route.name && typeof(route.meta) !== 'undefined'){
                  // console.log(route.name + ' : ' + route.meta['title'])
                  this.breadcrumbArray.push(route);
                }
              }
            )
          }
        )
        // console.log(this.breadcrumbArray)
      }
    },
    watch: {
      showBreadcrumb() {
        this.setBreadcrumb();
      },
      $route(to, from) {
        // react to route changes...
        this.interpretBreadCrumb();
      }
    },
    created() {
      this.interpretBreadCrumb()
      this.setBreadcrumb();
    }
  }
</script>