<template>
  <div v-if="data" id="featured-pages" class="grid sm-2 gap-20">
    <inline-svg :src="$getImageSrc('arrow.svg')" id="foo" class="col sm-col-2"></inline-svg>
    <router-link v-for="(page,index) in data.featuredPages" :to="$buildInternalLink(page.pageLink)">
      <div class="inner grid gap-20">
        <h4>{{getFeaturedPageTitle(index)}}</h4>
        <h3 class="heading">{{page.pageLinkDescription}}</h3>
      </div>
      <div class="is-cover three-two">
        <image-helper :data="imageUrl(index)"></image-helper>
      </div>
      
    </router-link>
  </div>
</template>


<script>

  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
      }
    },
    methods:{
      imageUrl(index){
        return this.data.featuredPages[index].pageLinkImage
      },
      getFeaturedPageTitle(index){
        return this.data.featuredPages[index].pageLink[0].title
      },
      getFeaturedPageLink(index){
        let fullLink = this.data.featuredPages[index].pageLink[0].url
        let relativeLink = fullLink.replace(/https?:\/\/[^\/]+/i, "");
        return relativeLink
      },
    }
  }
</script>