<template>
  <div id="status-bar">
    <div class="result-reset-wrapper grid">
      <p v-if="results" class="small"><strong>{{results}}</strong> matching results</p>
      <p v-if="reset && !isGlobal" class="small strong reset" @click="$emit('reset')">RESET</p>
    </div>
    <div class="filter-wrapper">
      <div class="select-wrapper view" v-if="results > 1 && results > batchData.default">
        <span>View</span>
        <select v-model="batchSize">
          <option v-for="(option, index) in batchData.options" :value="$toKebab(option)">{{option}}</option>
        </select>
      </div>

      <div class="select-wrapper order" v-if="results > 1 && data.orderBy.length > 1">
        <span>Order by</span>

        <!-- Use span to get a dynamic width -->
        <span v-for="option in data.orderBy" v-if="$toKebab(option) === filters.orderBy && !isXs">{{option}}</span>

        <select v-model="orderBy">
          <option v-for="(option, index) in data.orderBy" :value="$toKebab(option)">{{option}}</option>
        </select>
      </div>

    </div>
  </div>
</template>


<script>

  export default {
    props: {
      total: {
        type: Number,
        required: true
      },
      batchData: {
        type: Object,
        required: true
      },
      reset: {
        type: Boolean,
        required: true
      }
    },
    data(){
      return {
        showList:  false,
      }
    },
    computed: {
      data() {
        return this.$store.state.craftPayload.searchSettings
      },
      filters() {
        return this.$store.state.activeFilters
      },
      batchSize: {
        get() {
          return this.$store.state.activeFilters.batchSize
        },
        set(value) {
          this.$setActiveFilters({batchSize: value})
        }
      },
      orderBy: {
        get() {
          return this.$store.state.activeFilters.orderBy
        },
        set(value) {
          this.$setActiveFilters({orderBy: value})
        }
      },
      results() {
        return this.total
      },
      hasFilters() {
        // batchSize / orderBy / pagination are always set so count up from there
        let filterCount = 0
        let filters = this.filters

        Object.keys(filters).forEach(function (key) {
          if(filters[key] !== "") {
            filterCount ++
          }
        });
        return filterCount > 3 
      },
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      isGlobal() {
        return this.$route.name === 'searchIndex'
      }
    },
    mounted() {
      if (this.$store.state.graphQLDebug){
        //console.log(this.data.orderBy)
        //console.log(this.filters.orderBy)
      }
    }
  }
</script>

