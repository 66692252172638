<template>
  <div id="introduction" class="container">
    <h1>{{getIntroduction().introductionTitleL2}}</h1>
    <p v-if="getIntroduction().introductionBylineL2" class="strong">{{getIntroduction().introductionBylineL2}}</div>
  </div>
</template>


<script>
  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
      }
    },
    methods: {
      getIntroduction(){
        return this.data.introductionModuleL2[0]
      }
    }
  }
</script>