<template>
  <content-wrapper id="article" :class="data.slug" class="small-gap" v-if="data.result">

    <article-detail :data="data.entry"></article-detail>

    <!-- start dynamic component ordering -->
    <template v-for="component in orderableComponents">
      <component v-for="componentItem in component" :is="$toKebab(componentItem.__typename)" :data="componentItem"></component>
    </template>

    <template v-slot:secondary>
      <useful-links v-if="data.entry.usefulLinks" :data="data.entry.usefulLinks"></useful-links>
      <share :data="data.entry"></share>
    </template>

    <template v-slot:footer>
      <downloads v-if="data.entry.downloads" :data="data.entry.downloads"></downloads>
      <related-content v-if="checkRelatedContent" :data="data.entry"></related-content>
      <contact-form v-if="data.entry.contactForm[0]" :data="data.entry.contactForm[0]"></contact-form>
    </template>

  </content-wrapper>
</template>

<script>
  import ContentWrapper from '~/components/ContentWrapper.vue'
  import ArticleDetail from '~/components/article/ArticleDetail.vue'
  import InlineImages from '~/components/InlineImages.vue'
  import Quote from '~/components/article/Quote.vue'
  import Accordion from '~/components/Accordion.vue'
  import Wysiwyg from '~/components/Wysiwyg.vue'
  import UsefulLinks from '~/components/UsefulLinks.vue'
  import Share from '~/components/article/Share.vue'
  import RelatedContent from '~/components/RelatedContent.vue'
  import Downloads from '~/components/Downloads.vue'
  import ContactForm from '~/components/ContactForm.vue'
  import gql from 'graphql-tag'
  import { FRAGMENT_ARTICLE_FIELDS } from '~/gqlFragments.js';

  export default {
    components: { ContentWrapper, ArticleDetail, InlineImages, Quote, Wysiwyg, Accordion, UsefulLinks, Share, RelatedContent, Downloads, ContactForm
    },
    data(){
      return {
        data: {
          slug: this.$route.name,
          title: 'Title',
          pageTitle: '',
          skipQuery: true,
          entry: Object,
          result: false,
        }
      }
    },
    apollo: {
      casestudy: {
        query: gql`
           query variables($section: [String!], $slug: [String!]){
            casestudy: entry(section: $section, slug: $slug) {
              id,
              title,
              fullTitle,
              ...on caseStudies_caseStudies_Entry{
                articleIntroduction: articleIntroduction,
                authors{
                  ... on people_people_Entry{
                    firstName, lastName, qualification, jobTitle, studentProfile, externalLink
                  }
                },
                departments{
                    id, name: title, link: slug
                },
                publishedDate @formatDateTime (format: "d M Y"),
                researchAreas{
                  title
                },
                contentModules{
                    __typename
                    ...linkGroupModuleArticle
                    ...wysiwygModuleArticle
                    ...inlineImagesModuleArticle
                    ...accordionModuleArticle
                    ...QuoteArticle
                  },
                  heroImage{
                    url,
                    width,
                    height,title,
                    focalPoint,
                    mimeType,
                    s400: url @transform(width: 400),
                    s800: url @transform(width: 800),
                    s1200: url @transform(width: 1200),
                    s1600: url @transform(width: 1600),
                    s2000: url @transform(width: 2000),
                    s2400: url @transform(width: 2400),
                    s2800: url @transform(width: 2800),
                    s3200: url @transform(width: 3200)
                  },
                  usefulLinks{
                    ...on usefulLinks_usefulLinks_BlockType{
                      linkTitle, linkExternal, existingPage{
                        slug, sectionHandle
                      }
                    }
                  },
                  downloads{
                    ...on downloads_downloads_BlockType{
                      title: downloadTitle, 
                      downloadFile{
                        url,kind,size
                      }
                    }
                  },
                  relatedLinks{
                    title, slug, sectionHandle, parent {title, sectionHandle}
                  },
                  contactForm{
                    ...contactFormArticle
                  },
                  showRelatedEvents,
                  showRelatedUpdates
                }
              }
            }
          ${FRAGMENT_ARTICLE_FIELDS}
        `,
        variables() {
          return {
            section: 'caseStudies',
            slug: this.$route.params.slug
          }
        },
        skip() {
          return this.skipQuery
        },
        result ({ data, loading, networkStatus }) {
          if (data.casestudy !== null){
            this.data.pageTitle = data.casestudy.title
            this.data.entry = data.casestudy
            this.$store.commit('setTitle',data.casestudy.title)
            this.$store.commit('setLoaded',true)
            this.data.result = true
            if (this.$store.state.graphQLDebug){
              console.log('Full graphQL entry data:')
              console.log(data.casestudy)
            }
          }
          else{
            this.$router.push({ name: 'errorContent' })
          }
        }
      },
      update: {
        query: gql`
          query variables($section: [String!], $slug: [String!]){
            update: entry(section: $section, slug: $slug) {
              id,
              title,
              fullTitle,
              ...on updates_updates_Entry{
                articleIntroduction: articleIntroduction,
                authors{
                  ... on people_people_Entry{
                    firstName, lastName, qualification, jobTitle, studentProfile, externalLink
                  }
                },
                departments{
                    id, name: title, link: slug
                },
                researchAreas{
                    id, name: title, link: slug
                },
                publishedDate @formatDateTime (format: "d M Y"),
                contentModules{
                  __typename
                  ...linkGroupModuleArticle
                  ...wysiwygModuleArticle
                  ...inlineImagesModuleArticle
                  ...accordionModuleArticle
                  ...QuoteArticle
                },
                heroImage{
                  url,
                  width,
                  height,title,
                  focalPoint,
                  mimeType,
                  s400: url @transform(width: 400),
                  s800: url @transform(width: 800),
                  s1200: url @transform(width: 1200),
                  s1600: url @transform(width: 1600),
                  s2000: url @transform(width: 2000),
                  s2400: url @transform(width: 2400),
                  s2800: url @transform(width: 2800),
                  s3200: url @transform(width: 3200)
                },
                usefulLinks{
                  ...on usefulLinks_usefulLinks_BlockType{
                    linkTitle, linkExternal, existingPage{
                      slug, sectionHandle
                    }
                  }
                },
                downloads{
                  ...on downloads_downloads_BlockType{
                    title: downloadTitle, 
                    downloadFile{
                      url,kind,size
                    }
                  }
                },
                relatedLinks{
                  title, slug, sectionHandle, parent {title, sectionHandle}
                },
                contactForm{
                    ...contactFormArticle
                },
                showRelatedEvents,
                showRelatedUpdates
              }
            }
          }
          ${FRAGMENT_ARTICLE_FIELDS}
        `,
        variables() {
          return {
            section: 'updates',
            slug: this.$route.params.slug
          }
        },
        skip() {
          return this.skipQuery
        },
        result ({ data, loading, networkStatus }) {
          if (data.update !== null){
            this.data.pageTitle = data.update.title
            this.data.entry = data.update
            this.$store.commit('setTitle',data.update.title)
            this.$store.commit('setLoaded',true)
            this.data.result = true;
            if (this.$store.state.graphQLDebug){
              console.log('Full graphQL entry data:')
              console.log(data.update)
            }
          }
          else{
            this.$router.push({ name: 'errorContent' })
          }
        }
      },
      event: {
        query: gql`
          query variables($section: [String!], $slug: [String!]){
            event: entry(section: $section, slug: $slug) {
              id,
              title,
              fullTitle,
              ...on events_events_Entry {
                articleIntroduction: articleIntroduction,
                eventTime,
                eventDate @formatDateTime (format: "d M Y"),
                eventLocation{
                  ...on eventLocation_eventLocation_BlockType{
                    locationName, locationLink
                  }
                },
                departments{
                    id, name: title, link: slug
                },
                researchAreas{
                    id, name: title, link: slug
                },
                contentModules{
                  __typename
                  ...linkGroupModuleArticle
                  ...wysiwygModuleArticle
                  ...inlineImagesModuleArticle
                  ...accordionModuleArticle
                  ...QuoteArticle
                },
                heroImage{
                  url,
                  width,title,
                  height,
                  focalPoint,
                  mimeType,
                  s400: url @transform(width: 400),
                  s800: url @transform(width: 800),
                  s1200: url @transform(width: 1200),
                  s1600: url @transform(width: 1600),
                  s2000: url @transform(width: 2000),
                  s2400: url @transform(width: 2400),
                  s2800: url @transform(width: 2800),
                  s3200: url @transform(width: 3200)
                },
                usefulLinks{
                  ...on usefulLinks_usefulLinks_BlockType{
                    linkTitle, linkExternal, existingPage{
                      slug, sectionHandle
                    }
                  }
                },
                downloads{
                  ...on downloads_downloads_BlockType{
                    title: downloadTitle, 
                    downloadFile{
                      url,kind,size
                    }
                  }
                },
                relatedLinks{
                  title, slug, sectionHandle, parent {title, sectionHandle}
                },
                contactForm{
                  ...contactFormArticle
                },
                showRelatedEvents,
                showRelatedUpdates
              }
            }
          }
          ${FRAGMENT_ARTICLE_FIELDS}
        `,
        variables() {
          return {
            section: 'events',
            slug: this.$route.params.slug
          }
        },
        skip() {
          return this.skipQuery
        },
        result ({ data, loading, networkStatus }) {
          if (data.event !== null){
            this.data.pageTitle = data.event.title
            this.data.entry = data.event
            this.$store.commit('setTitle',data.event.title)
            this.$store.commit('setLoaded',true)
            this.data.result = true;
            if (this.$store.state.graphQLDebug){
              console.log('Full graphQL entry data:')
              console.log(data.event)
            }
          }
          else{
            this.$router.push({ name: 'errorContent' })
          }
        }
      },
    },
    computed: {
      orderableComponents() {
        let contentModuleSet = [];
        if (typeof(this.data.entry.contentModules) !== 'undefined'){
          this.data.entry.contentModules.forEach(contentModule=>{
            // contentModules come through as contentModules_LinkGroup_BlockType via graphQL, transpose back to matching Vue component
            let moduleIdentifierParts = contentModule.__typename.split('_')
            let actualModule = moduleIdentifierParts[1]
            let contentModuleItem = [];
            // create array and push into Content Module set
            contentModuleItem[actualModule] = contentModule
            contentModuleSet.push(contentModuleItem)
            contentModule.__typename = actualModule;
          })
          return [this.data.entry.contentModules];
        }
        else
          return [];
      },
      fullPath() {
        return window.location.href
      },
      checkLoaded(){
        return this.data.result
      },
      checkRelatedContent(){
        if (this.data.entry.relatedLinks.length || this.data.entry.showRelatedEvents || this.data.entry.showRelatedUpdates)
          return true
        else
          return false
      }
    },
    methods: {
      getContent(){
        
        let route = this.$route.name
        switch(route){
          case 'ourResearch.ourResearchCaseStudies.caseStudiesChildren':
            this.$apollo.queries.casestudy.skip = false
            this.$apollo.queries.casestudy.refetch()
            
          break;
          case 'updatesIndex.updates':
            this.$apollo.queries.update.skip = false
            this.$apollo.queries.update.refetch()
            
          break;
          case 'eventsIndex.events':
            this.$apollo.queries.event.skip = false
            this.$apollo.queries.event.refetch()
          break;
          default:
            // handle odd route call of this component (500 or 404)
          break;
        }
        
      },
    },
    metaInfo() {
      return {
        meta: [
          { vmid:'og:title', property: 'og:title', content: this.data.title },
          { vmid:'og:description', property: 'og:description', content: this.data.introduction },
          //{ vmid:'og:image', property: 'og:image', content: window.location.origin + this.$getImageSrc(this.data.heroImage) },
          { vmid:'og:url', property:'og:url', content: window.location.origin }
        ]
      }
    },
    created(){
      this.getContent()
    }
  }
</script>