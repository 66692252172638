<template>
  <header :class="{'search-active' : searchActive, 'menu-active' : menuActive}">
    <div class="menu container">
      <router-link :to="{name: 'home'}" :class="{'disabled' : isHome}" class="logo">
        <inline-svg v-if="showFullLogo" :src="$getImageSrc('logo-full-v.svg')" class="full" :fill="logoFill"></inline-svg>
        <inline-svg v-else :src="$getImageSrc('icon.svg')" class="icon" :fill="iconFill"></inline-svg>
      </router-link>
      <inline-svg v-if="!menuActive && !searchActive && !searchPage" :src="$getImageSrc('search.svg')" class="search" @click="toggleSearch()"></inline-svg>
      <div id="hamburger" :class="{'active' : overlayActive, 'white' : searchActive}" @click="hamburgerMenu()">
        <span></span>
      </div>
    </div>
    <breadcrumb></breadcrumb>
    <menu-overlay :entries="menuEntries()" v-if="checkData() && menuActive"></menu-overlay>
    <search-overlay v-if="searchActive"></search-overlay>
  </header>
</template>


<script>
  import Breadcrumb from "~/components/Breadcrumb.vue";
  import MenuOverlay from "~/components/MenuOverlay.vue";
  import SearchOverlay from "~/components/SearchOverlay.vue";
  import gql from 'graphql-tag'

  const dBlue = '#242250'
  const lBlue = '#2b4ae7'

  export default {
    components: { Breadcrumb, MenuOverlay, SearchOverlay },
    data(){
      return {
        data: {
          result: false,
        }
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      isHome() {
        return this.$route.name === 'home'
      },
      menuActive() {
        return this.$store.state.menu
      },
      searchActive() {
        return this.$store.state.search
      },
      overlayActive() {
        return this.menuActive || this.searchActive
      },
      showFullLogo() {
        if(this.isXs && this.overlayActive) {
          return false
        } else {
          return true
        }
      },
      logoFill() {
        return (!this.isXs && this.searchActive) ? 'white' : lBlue
      },
      iconFill() {
        return (this.searchActive) ? 'white' : lBlue;
      },
      searchPage() {
        return this.$route.name === 'searchIndex'
      }
    },
    methods: {
      toggleSearch(){
        this.$store.commit('setSearch', !this.searchActive)
        this.$store.commit('setMenu', false)
      },
      toggleMenu(){
        this.$store.commit('setSearch', false)
        this.$store.commit('setMenu', !this.menuActive)
      },
      hamburgerMenu(){
        if (this.searchActive){
          this.toggleSearch()
        } else {
          this.toggleMenu()
        }
      },
      checkData(){
        return this.$store.state.loaded
      },
      menuEntries(){
        return this.$store.state.menuEntries
      }
    }
  }
</script>