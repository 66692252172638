
<template>
  <div v-if="data.featuredNewsEvents.length" id="featured-news-events">
    <h4>NEWS & Events</h4>

    <carousel-helper :options="options" :custom-nav="!isXs" :custom-pagination="isXs">

      <swiper-slide v-for="(item, index) in data.featuredNewsEvents" :key="index" class="slide">
        <router-link :to="$buildInternalLink(item)" class="white">
          <div class="is-cover three-two">
            <image-helper :data="item.heroImage"></image-helper>
          </div>
          <div class="inner grid gap-10">
            <h2>{{item.title}}</h2>
            <p v-if="item.eventDate || item.eventTime || item.publishedDate" class="small">
              <template v-if="item.publishedDate">{{item.publishedDate}}</template>
              <template v-if="item.eventDate">{{item.eventDate}}</template>
              <template v-if="item.eventDate && item.eventTime"><br/></template>
              <template v-if="item.eventTime">{{item.eventTime}}</template>
            </p>
          </div>
        </router-link>
      </swiper-slide>

    </carousel-helper>
  </div>
</template>

<script>
  import CarouselHelper from '~/components/CarouselHelper.vue'
  import { SwiperSlide } from 'vue-awesome-swiper'

  export default {
    components: { CarouselHelper, SwiperSlide },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        options: {
          slidesPerView: 1,
          spaceBetween: 10,
          speed: 600,
          loop: true,
          slidesPerGroup: 1,
          grabCursor: true,
          breakpoints: {
            1200: {
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
            376: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
          }
        }
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      }
    },
    methods:{
      getRoute(index){
        let slug = this.data.featuredNewsEvents[index].slug
        let routeName = this.data.featuredNewsEvents[index].sectionHandle+'Index.'+this.data.featuredNewsEvents[index].sectionHandle
        return{
          name: routeName,
          slug: slug
        }
      }
    }
  }
</script>