<template>
  <div id="ltl-search">
    <form ref="form" :action="computedAction" :method="computedMethod" target="_blank">
      <input type="hidden" name="scope" id="scope">
      <input type="hidden" name="groups" id="groups">
      <input type="hidden" name="categories" id="categories">
      <input type="hidden" name="q" id="q">
      <div id="filter-bar" :class="colCount">

       <div class="select-wrapper">
         <select v-model="activeFilters.primary">
           <option value="" disabled selected>Filter by {{computedPrimary.title}}</option>
           <option value="" disabled>–</option>
           <option v-for="option in computedPrimary.options" :value="$toKebab(option.key)">{{option.label}}</option>
         </select>
       </div>

       <div v-if="showSecondary" class="select-wrapper">
         <select v-model="activeFilters.secondary">
           <option value="" disabled selected>Filter by {{computedSecondary.title}}</option>
           <option value="" disabled>–</option>
           <option v-for="option in computedSecondary.options" :value="$toKebab(option.key)">{{option.label}}</option>
         </select>
       </div>

        <div class="search-wrapper">
          <inline-svg :src="$getImageSrc('search.svg')"></inline-svg>
          <input type="text" name="query" placeholder="Search" v-model="activeFilters.search" @keydown.enter="submitSearch($event)">
        </div>
 
        <!-- <inline-svg :src="$getImageSrc('search.svg')" @click="submitSearch()"></inline-svg> -->
      </div>
    <span class="submit" @click="submit()">Submit</span>
    </form>
  </div>
 
</template>


<script>

  export default {
    data(){
      return {
        ltlPublicationsAction: 'https://researcharchive.lincoln.ac.nz/discover',
        ltlPublicationsMethod: 'POST',
        ltlDataAction: 'https://data.lincoln.ac.nz/search',
        ltlDataMethod: 'GET',
        activeFilters : {
          primary : '',
          secondary : '',
          search : ''
        },
        filters: {
          publications:{
            primary : {
              title: 'Communities',
              options: [
                        {key:'10182-3',label:'Faculty of Agribusiness and Commerce'},
                        {key:'10182-1',label:'Faculty of Agriculture and Life Sciences'},
                        {key:'10182-4',label:'Faculty of Environment, Society and Design'},
                        {key:'10182-8477',label:'Postgraduate Research'},
                        {key:'10182-5',label:'Research Centres and Units'},
                        {key:'10182-6',label:'Theses and Dissertations'}
              ]
            },
            secondary : [
              {
                key: '10182-3',
                title: 'Collections', // Faculty of Agribusiness and Commerce
                options: [
                          {key:'10182-255',label:'Agribusiness and Commerce series (all)'},
                          {key:'10182-256',label:' - Commerce Division Discussion Paper series'},
                          {key:'10182-4745',label:' - Faculty of Agribusiness & Commerce Working Paper series'},
                          {key:'10182-4463',label:' - Faculty of Commerce Research Report series'},
                          {key:'10182-5042',label:' - Farm Budget Manual series'},
                          {key:'10182-5702',label:' - Farm Management Papers'},
                          {key:'10182-5639',label:' - Farm Management Studies'},
                          {key:'10182-5573',label:' - Farm Technical Manual series'},
                          {key:'10182-49',label:' - Farm and Horticultural Management Group Research Report series'},
                          {key:'10182-5043',label:' - Financial Budget Manual series'},
                          {key:'10182-4247',label:' - Kellogg Rural Leaders Programme report series'},
                          {key:'10182-7052',label:' - Proceedings of the Lincoln College Farmers\' Conference series'},
                          {key:'10182-7051',label:' - Rural Education Bulletin series'},
                          {key:'10182-6413',label:'Department of Agribusiness and Markets'},
                          {key:'10182-42',label:'Department of Financial and Business Systems'},
                          {key:'10182-41',label:'Department of Global Value Chains and Trade'},
                          {key:'10182-44',label:'Department of Land Management and Systems'}
                ]
              },
              {
                key: '10182-1',
                title: 'Collections', // Faculty of Agriculture and Life Sciences
                options: [
                          {key:'10182-40',label:'Agriculture and Life Sciences Division Research Report series (all)'},
                          {key:'10182-519',label:' - Agriculture and Life Sciences Division Research Report series'},
                          {key:'10182-173',label:' - Bio-Protection & Ecology Technical Report series'},
                          {key:'10182-1333',label:' - Lincoln College Technical Publication series'},
                          {key:'10182-169',label:' - Lincoln University Wildlife Management Report series'},
                          {key:'10182-34',label:'Department of Agricultural Sciences'},
                          {key:'10182-35',label:'Department of Pest Management and Conservation'},
                          {key:'10182-39',label:'Department of Soil and Physical Sciences'},
                          {key:'10182-38',label:'Department of Wine, Food and Molecular Biosciences'}
                ]
              },
              {
                key: '10182-4',
                title: 'Collections', // Faculty of Environment, Society and Design
                options: [
                          {key:'10182-220',label:'Environment, Society and Design series collections (all)'},
                          {key:'10182-221',label:' - Applied Computing Research Report series'},
                          {key:'10182-1398',label:' - Department of Horticulture, Landscape and Parks Bulletin series'},
                          {key:'10182-1397',label:' - Department of Parks, Recreation and Tourism Occasional Paper series'},
                          {key:'10182-5105',label:' - Landscape Architecture Section, Horticulture Department, Study series'},
                          {key:'10182-46',label:'Department of Environmental Management'},
                          {key:'10182-45',label:'Department of Informatics and Enabling Technologies'},
                          {key:'10182-48',label:'Department of Tourism, Sport and Society'},
                          {key:'10182-33',label:'School of Landscape Architecture'}
                ]
              },
              {
                key: '10182-5',
                title: 'Collections', // Research Centres and Units
                options: [
                          {key:'10182-7',label:'Agribusiness and Economics Research Unit (AERU)'},
                          {key:'10182-807',label:'Bio-Protection Research Centre'},
                          {key:'10182-454',label:'Centre for Advanced Computational Solutions'},
                          {key:'10182-578',label:'Centre for Land Environment and People (LEaP)'},
                          {key:'10182-804',label:'Centre for Resource Management (CRM)'},
                          {key:'10182-428',label:'Centre for Soil and Environmental Research'},
                          {key:'10182-1083',label:'Centre for Viticulture and Oenology'},
                          {key:'10182-6405',label:'Centre for Wildlife Management and Conservation'},
                          {key:'10182-9557',label:'Centre of Excellence in Farm Business Management'},
                          {key:'10182-1358',label:'Isaac Centre for Nature Conservation'},
                          {key:'10182-3847',label:'Learning, Teaching and Library'},
                          {key:'10182-5147',label:'Lincoln Agritech Limited'},
                          {key:'10182-1289',label:'Lincoln Ventures Limited'},
                          {key:'10182-1963',label:'New Zealand Agricultural Engineering Institute (NZAEI)'},
                          {key:'10182-8',label:'Tourism Recreation Research and Education Centre (TRREC)'},
                          {key:'10182-1395',label:'Tussock Grasslands and Mountain Lands Institute (TGMLI)'},
                          {key:'10182-8660',label:'University Studies and English Language (USEL)'},
                          {key:'10182-10379',label:'Waterways Centre for Freshwater Management'}
                ]
              },
              {
                key: '10182-6',
                title: 'Collections', // Theses and Dissertations
                options: [
                          {key:'10182-257',label:'Dissertations'},
                          {key:'10182-9',label:'Doctoral (PhD) Theses'},
                          {key:'10182-10',label:'Masters Theses'},
                          {key:'10182-5713',label:'National Diploma'},
                          {key:'10182-337',label:'Theses and Dissertations with Restricted Access'}
                ]
              },
            ]
          },
          data:{
            primary : {
              title: 'Group',
              options: [
                        {key:'21318',label:'Lincoln University'},
                        {key:'22991',label:'Bio-Protection Research Centre'},
                        {key:'22973',label:'Faculty of Agriculture and Life Sciences'},
                        {key:'22997',label:'Faculty of Agribusiness and Commerce'},
                        {key:'-1',label:'Other'}
              ]
            },
            secondary : [
              {
                title: 'Category',
                options: [
                        {key:'39',label:'Ecology'},
                        {key:'48',label:'Biological Sciences'},
                        {key:'133',label:'Plant Biology'},
                        {key:'734',label:'Biological Sciences'},
                        {key:'272',label:'Environmental Sciences'},
                        {key:'24',label:'Evolutionary Biology'},
                        {key:'8',label:'Microbiology'},
                        {key:'873',label:'Chemical Sciences'},
                        {key:'13',label:'Genetics'},
                        {key:'207',label:'Meteorology'},
                        {key:'21',label:'Biotechnology'},
                        {key:'132',label:'Infectious Diseases'},
                        {key:'61',label:'Developmental Biology'},
                        {key:'4',label:'Biochemtistry'},
                        {key:'7',label:'Medicine'},
                        {key:'45',label:'Sociology'},
                        {key:'14',label:'Molecular Biology'},
                        {key:'106',label:'Science Policy'},
                        {key:'15',label:'Neuroscience'},
                        {key:'134',label:'Virology'}
              ]
              }
            ]
          }
        }   
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      colCount() {
        if(this.isXs) {
          return false
        } else {
          if(this.activeFilters.primary === '' && this.$route.name !== 'ourResearch.ourResearchData') {
            return 'sm-2'
          } else {
            return 'sm-3'
          }
        }
      },
      computedPrimary(){
        if (this.$route.name === 'ourResearch.ourResearchPublications')
          return this.filters.publications.primary
        else
          return this.filters.data.primary
      },
      computedSecondary() {
        let result = []
        if (this.$route.name === 'ourResearch.ourResearchPublications'){
          let primary = this.activeFilters.primary
          let secondary = this.filters.publications.secondary
          this.activeFilters.secondary = ''
          secondary.forEach(function (value, index) {
            if(value.key == primary) {
              result =  value
            }
          });
        }
        else{
          this.activeFilters.secondary = ''
          result = this.filters.data.secondary[0]
        }
        return result
      },
      computedAction(){
        if (this.$route.name === 'ourResearch.ourResearchPublications')
          return this.ltlPublicationsAction
        else
          return this.ltlDataAction
      },
      computedMethod(){
        if (this.$route.name === 'ourResearch.ourResearchPublications')
          return this.ltlPublicationsMethod
        else
          return this.ltlDataMethod
      },
      showSecondary(){
        if (this.$route.name === 'ourResearch.ourResearchData')
          return true
        else{
          let result = false
          if (this.activeFilters.primary){
            let primary = this.activeFilters.primary
            let secondary = this.filters.publications.secondary
            this.activeFilters.secondary = ''
            secondary.forEach(function (value, index) {
              if(value.key == primary) {
                result = true
              }
            });
          }
          return result
        }
      },
    },
    methods: {
      submit(){
        if (this.$route.name === 'ourResearch.ourResearchPublications'){
          if (this.activeFilters.secondary.replace('-','/') != ''){
            this.$refs.form.scope.value = this.activeFilters.secondary.replace('-','/')
            this.$refs.form.submit()
          }
          else{
            this.$refs.form.scope.value = this.activeFilters.primary.replace('-','/')
            this.$refs.form.submit()
          }
        }
        else{
          if (this.activeFilters.secondary != '')
            this.$refs.form.categories.value = this.activeFilters.secondary
          this.$refs.form.groups.value = this.activeFilters.primary
          this.$refs.form.q.value = this.$refs.form.query.value
          this.$refs.form.submit()
        }
      },
    },
  }
</script>