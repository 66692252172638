<template>
  <div v-if="data" class="featured-students grid gap-20">
    <h3>Featured Student Profiles</h3>
    <div class="student grid" v-for="student,index in data.students">
      <div v-if="!isXs" class="is-cover one-one">
        <image-helper :data="student.profileImage"></image-helper>
      </div>
      <p>
        <strong>{{getStudentName(index)}}</strong><br>
        {{student.studentProfileDescription}}<br>
        <router-link :to="{name: 'postgraduateStudy.studentProfiles.studentProfilesChildren', params: {'slug': student.slug}}">View profile</router-link>
      </p>
    </div>
  </div>
</template>


<script>
  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      }
    },
    methods:{
      getStudentName(index){
        return this.data.students[index].personLink[0].firstName + ' ' + this.data.students[index].personLink[0].lastName
      }
    },
  }
</script>