<template>
  <div v-if="data.length" id="hero-image" class="is-cover">
    <image-helper :data="data"></image-helper>
  </div>
</template>


<script>

  export default {
    props: {
      data: {
        type: Array,
        required: true
      }
    },
    data(){
      return {
      }
    }
  }
</script>