<template>
  <footer>
  	<div class="container">
    	<ul class="primary">
    		<li class="strong back-to-top" v-scroll-to="'main'">
  				<inline-svg :src="$getImageSrc('back-to-top.svg')"></inline-svg>Back to top
    		</li>
    		<li class="strong">
			<a href="https://researchers.lincoln.ac.nz">Find a researcher / supervisor</a>
    		</li>
         
    	</ul>
    	<div class="secondary">
    		<ul class="social">
    			<li>
    				<a href="https://www.instagram.com/lincolnuninz" target="_blank">
  						<inline-svg :src="$getImageSrc('instagram.svg')"></inline-svg>
    				</a>
    			</li>
    			<li>
    				<a href="https://www.facebook.com/LincolnUniNZ" target="_blank">
    					<inline-svg :src="$getImageSrc('facebook.svg')"></inline-svg>
    				</a>
    			</li>
    			<li>
    				<a href="https://twitter.com/LincolnUniNZ" target="_blank">
    					<inline-svg :src="$getImageSrc('twitter.svg')"></inline-svg>
    				</a>
    			</li>
    			<li>
    				<a href="https://www.youtube.com/user/LincolnUniversityNZ" target="_blank">
    					<inline-svg :src="$getImageSrc('youtube.svg')"></inline-svg>
    				</a>
    			</li>
    		</ul>
    		<ul class="fine-print">
    			<li><router-link :to="{name: 'privacy-policy'}">Privacy policy</router-link></li>
    			<li><router-link :to="{name: 'disclaimer'}">Disclaimer</router-link></li>
    			<li><a :href="'mailto:vcoffice@lincoln.ac.nz?subject=' + $store.state.title + ' Feedback'">Website feedback</a></li>
    			<li>© Lincoln University 2020</li>
    		</ul>
    	</div>
  	</div>
  </footer>
</template>


<script>
  export default {
    data(){
      return {
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>