import Images from './assets/img/**/*.*'
import Videos from './assets/video/**/*.*'
import Store from './store.js'

var VueScrollTo = require('vue-scrollto');
var Helpers = {}

Helpers.getImageSrc = (target) => {
  return Helpers.getSrc(target,Images);
}

Helpers.getVideoSrc = (target) => {
  return Helpers.getSrc(target,Videos);
}

Helpers.getSrc = (target, src) => {
  if(target !== undefined) {
    if(target.charAt(0) === '/') {
      target = target.substr(1); // remove leading slash if it was left in by accident
    }
    target = target.split('.')[0] // remove ext
    target = target.split('/') // split on slashes
  }
  target.forEach(function (value, index) {
    src = src[value]
  });
  return src[Object.keys(src)[0]];
}

Helpers.fullUrl = (target) => {
    if(target !== undefined) {
      return Store.state.fullURL + target
    }
}

Helpers.getVideoSrc = (target) => {
	target = Helpers.parseSrc(target)
	let obj = Videos[target]
	return obj[Object.keys(obj)[0]];
}

Helpers.parseSrc = (target) => {
	if(target !== undefined) {
		target = target.split('.')[0]
		target = target.split('/')
		target = target[target.length -1]
		return target
	} 
	return false
}

Helpers.displayUrl = (url) => {
  if(url !== undefined) {
    if(url.startsWith('https://')) {
      url = url.split('https://')[1]
    }
    if(url.startsWith('http://')) {
      url = url.split('http://')[1]
    }
    if(url.startsWith('www.')) {
      url = url.split('www.')[1]
    }
    if(url.includes('/')) {
      url = url.split('/')[0];
    }
    return url
  }
}


// useage : <p class="foo" v-html="$wrapText('p','foo', property.description)"></p>
Helpers.wrapText = (tag,className,data) => {
  let startTag = '<' + tag + '>'
  let endTag = '</' + tag + '>'
  if (className) {
    startTag = '<' + tag + ' class="' + className + '">';
  }
  if(data) {
    return data.replace(/\r\n\r\n?/g, endTag + startTag);
  }
}


Helpers.setActiveFilters = (update) => {
	let existing = Store.state.activeFilters;
	let payload = {...existing, ...update}
	Store.mutations.setActiveFilters(Store.state,payload)
}


Helpers.toKebab = (value) => {
  if(typeof value !== "string") {
    return value
  } else {
    value = value.replace(/([a-z])([A-Z])/g, '$1-$2')    // get all lowercase letters that are near to uppercase ones
    value = value.replace(/[^a-zA-Z0-9 ]/g, '-') // remove specials
    value = value.replace(/ /g, '-') // remove spaces
    value = value.replace(/-{1,}/g, '-') // remove multiple dashes
    value = value.toLowerCase()
    return value
  }
}


Helpers.formatTel = (number) => {
  if(number === undefined) {
    return false
  }
  // number = number.replace(/\s/g, '')
  if(number.startsWith('0')) {
    number = number.substring(1)
  }
  number = '+64 ' + number
  return number
}


Helpers.formatAuthors = (authors, mode, order) => { 
  order = (order) ? order : 'lastName'
  let result = []
  // let sortedAuthors =[]
  let sortedAuthors = Array.from(authors)
  let count = Object.keys(authors).length
  let type = Array.isArray(authors) ? 'array' : 'object'

  // if(type === 'object' || type === 'array' && count === 1) { // got a single author, as an object or objArray
  //   sortedAuthors = authors
  //   mode = 'display'
  // } else {
  //   authors = Array.from(authors) // ensure its not reactive
  //   sortedAuthors = authors.sort(function(a, b) {
  //     var a = a[order].toUpperCase();
  //     var b = b[order].toUpperCase();
  //     return (a < b) ? -1 : (a > b) ? 1 : 0;
  //   });
  // }

  if(type === 'object' || type === 'array' && count === 1) { // got a single author, as an object or objArray
    mode = 'display'
  }

  if(mode === 'display') {
    Object.keys(sortedAuthors).forEach(function (key) {
      let author = []
      author.push(sortedAuthors[key].peopleTitle)
      author.push(sortedAuthors[key].firstName)
      author.push(sortedAuthors[key].lastName)
      if(sortedAuthors[key].qualification) {
        author.push(', ' + sortedAuthors[key].qualification) // have to conditional as filter will parse the comma as a value
      }
      author = author.filter(n => n).join(' ')
      author = author.replace(/ ,/g, ',') // join leaves us with the comma in the wrong place
      result.push(author)
      sortedAuthors[key].displayName = author
    });
    // returns sorted Authors collection with additional displayName property
    return sortedAuthors
  }

  // Reserved for likely retired research publications mode
  if(mode === 'formal') {
    Object.keys(sortedAuthors).forEach(function (key) {
      let author = []
      author.push(sortedAuthors[key].lastName)
      author = author.filter(n => n)
      result.push(author)
    });


    if(Object.keys(result).length < 6) {
      result = result.join(', ')
      result = result.replace(/,(?=[^,]*$)/, ' &')
    } else {
      result.splice(1)
      result.push('et al.')
      result = result.join(' ')
    }
  }

  if(mode === 'formal' || result.length === 1) {
    result = result.toString()
  }
  
  return result
}


Helpers.formatDepartments = (departments) => { // objArray
  let results = []
  Object.keys(departments).forEach(function (key) {
    results.push(departments[key].name) 
  });
  return results.join(', ')
}


Helpers.scrollWithOffset = (target) => {
  let offset = -90
  if(Store.state.breakpoint !== 'xs') {
    if(Store.state.breadcrumb) {
      offset = -164
    } else {
      offset = -120
    }
  }
  VueScrollTo.scrollTo(target,500, { offset: offset })
}

Helpers.slugify = (text) => {
  return text
    .toString()                     // Cast to string
    .toLowerCase()                  // Convert the string to lowercase letters
    .normalize('NFD')       // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim()                         // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}


Helpers.buildInternalLink = (internalPage) => {
  let type = Array.isArray(internalPage) ? 'array' : 'object'
  let sectionHandle = '';
  let slug = '';

  if (type === 'array'){
    sectionHandle = internalPage[0].sectionHandle
    slug = internalPage[0].slug
  }
  else{
    sectionHandle = internalPage.sectionHandle
    slug = internalPage.slug
  }
  
  switch (sectionHandle){
    case 'caseStudies':
      return {
        name: 'ourResearch.ourResearchCaseStudies.caseStudiesChildren',
        params: {
          slug: slug
        }
      }
    break;
    case 'events':
      return {
        name: 'eventsIndex.events',
        params: {
          slug: slug
        }
      }
    break;
    case 'privacyPolicy':
      return {
        name: 'privacy-policy'
      }
    break;
    case 'faculties':
      return {
        name: 'ourResearch.facultiesResearchCentres.L3',
        params: {
          slug: slug
        }
      }
    break;
    case 'findAResearcherSupervisor':
      return {
        name: 'findAResearcherSupervisor'
      }
    break;
    case 'industryPartnershipsIndex':
      return {
        name: 'industry'
      }
    break;
    case 'industryChildren':
      return {
        name: 'industry.industryChildren',
        params: {
          slug: slug
        }
      }
    break;
    case 'ourResearchIndex':
      return {
        name: 'ourResearch'
      }
    break;
    case 'ourResearchChildren':
      if (slug == 'case-studies'){
        return {
          name: 'ourResearch.ourResearchCaseStudies',
        }
      }
      else if (slug == 'publications'){
        return {
          name: 'ourResearch.ourResearchPublications',
        }
      }
      else if (slug == 'research-data'){
        return {
          name: 'ourResearch.ourResearchData',
        }
      }
      else{
        return {
          name: 'ourResearch.ourResearchChildren',
          params: {
            slug: slug
          }
        }
      }
    break;
    case 'postgraduateStudyChildren':
      if (slug == 'student-profiles'){
        return {
          name: 'postgraduateStudy.studentProfiles'
        }
      }
      else{
        return {
          name: 'postgraduateStudy.postgraduateStudyChildren',
          params: {
            slug: slug
          }
        }
      }
    break;
    case 'postgraduateStudyIndex':
      return {
        name: 'postgraduateStudy'
      }
    break;
    case 'studentProfilesChildren':
      return {
        name: 'postgraduateStudy.studentProfiles.studentProfilesChildren',
        params: {
          slug: slug
        }
      }
    break;
    case 'testingAnalyticalServicesIndex':
      return {
        name: 'testingAnalyticalServices'
      }
    break;
    case 'testingAnalyticalServicesChildren':
      return {
        name: 'testingAnalyticalServices.testingAnalyticalServicesChildren',
        params: {
          slug: slug
        }
      }
    break;
    case 'updates':
      return {
        name: 'updatesIndex.updates',
        params: {
          slug: slug
        }
      }
    break;
    default:
      return{
        name: sectionHandle
      }
    break;
  }
}


const install = function(Vue, options){
	Vue.prototype.$getImageSrc 				= Helpers.getImageSrc
	Vue.prototype.$getVideoSrc 				= Helpers.getVideoSrc
  // Vue.prototype.$fullUrl            = Helpers.fullUrl
	Vue.prototype.$wrapText						= Helpers.wrapText
	Vue.prototype.$displayUrl 				= Helpers.displayUrl
	Vue.prototype.$setActiveFilters 	= Helpers.setActiveFilters
	Vue.prototype.$toKebab 						= Helpers.toKebab
  // Vue.prototype.$formatTel 					= Helpers.formatTel
  Vue.prototype.$formatDepartments  = Helpers.formatDepartments
  Vue.prototype.$formatAuthors      = Helpers.formatAuthors
  Vue.prototype.$scrollWithOffset   = Helpers.scrollWithOffset
  Vue.prototype.$buildInternalLink  = Helpers.buildInternalLink
  Vue.prototype.$slugify  = Helpers.slugify
}


Helpers.install = install
export default Helpers;

