// gqlFragments.js
import gql from 'graphql-tag'

export const FRAGMENT_HOME_FIELDS = gql`
 fragment featuredPagesModule on featuredPages_featuredPages_BlockType{
    pageLink{
      id, title, slug, sectionHandle, url
    }
    pageLinkDescription
    pageLinkImage{
      url,
      width,
      height,
      focalPoint,title,
      mimeType,
      s400: url @transform(width: 400),
      s800: url @transform(width: 800),
      s1200: url @transform(width: 1200),
      s1600: url @transform(width: 1600),
      s2000: url @transform(width: 2000),
      s2400: url @transform(width: 2400),
      s2800: url @transform(width: 2800),
      s3200: url @transform(width: 3200)
    }
  }
`;


export const FRAGMENT_PROFILE_FIELDS = gql`
  fragment wysiwygModuleProfile on contentModules_Wysiwyg_BlockType{
    wysiwygContent
  }

  fragment inlineImagesModuleProfile on contentModules_InlineImages_BlockType {
    images{
      __typename
      ...on images_BlockType{
        inlineImage{
          url,
          width,
          height,
          focalPoint,title,
          mimeType,
          s400: url @transform(width: 400),
      s800: url @transform(width: 800),
      s1200: url @transform(width: 1200),
      s1600: url @transform(width: 1600),
      s2000: url @transform(width: 2000),
      s2400: url @transform(width: 2400),
      s2800: url @transform(width: 2800),
      s3200: url @transform(width: 3200)
        },
        inlineImageCaption
      }
    }
  }
`;


export const FRAGMENT_L1_L2_FIELDS = gql`
  fragment introModuleL1L2 on introductionModule_introduction_BlockType {
    introductionTitle
    introductionByline
  }

  fragment heroImgModuleL1L2 on heroModule_hero_BlockType {
    heroImage{
      url,
      width,
      height,
      focalPoint,title,
      mimeType,
      s400: url @transform(width: 400),
      s800: url @transform(width: 800),
      s1200: url @transform(width: 1200),
      s1600: url @transform(width: 1600),
      s2000: url @transform(width: 2000),
      s2400: url @transform(width: 2400),
      s2800: url @transform(width: 2800),
      s3200: url @transform(width: 3200)
    }
    heroTitle
  }



  fragment linkGroupModuleL1L2 on contentModules_LinkGroup_BlockType {
    linkSet{
      __typename
      ...on linkSet_BlockType{
        linkSetTitle,
        linkSetByline, 
        linkSetURL { id, title, slug, sectionHandle, url},
        linkSetImage{
          url,
          width,
          height,
          focalPoint,title,
          mimeType,
          s400: url @transform(width: 400),
      s800: url @transform(width: 800),
      s1200: url @transform(width: 1200),
      s1600: url @transform(width: 1600),
      s2000: url @transform(width: 2000),
      s2400: url @transform(width: 2400),
      s2800: url @transform(width: 2800),
      s3200: url @transform(width: 3200)
        }
      }
    }
  }

  fragment wysiwygModuleL1L2 on contentModules_Wysiwyg_BlockType{
    __typename
    wysiwygContent
  }

  fragment accordionModuleL1L2 on contentModules_Accordion_BlockType {
    __typename
    groupTitle,
    groupByline
    items{
      __typename
      ...on items_BlockType{
        itemTitle,
        itemContent
      }
    }
  }

  fragment featuredStudentsModuleL1L2 on contentModules_FeaturedStudents_BlockType {
    students{
      __typename
      id,
      slug,
      ... on studentProfilesChildren_studentProfiles_Entry{
        studentProfileDescription,
        personLink{
        ... on people_people_Entry{
            peopleTitle,
            jobTitle,
            firstName,
            lastName,
            qualification
          }
        },
        profileImage{
          url,
          width,title,
          height,
          focalPoint,
          mimeType,
          s400: url @transform(width: 400),
          s800: url @transform(width: 800),
          s1200: url @transform(width: 1200),
          s1600: url @transform(width: 1600),
          s2000: url @transform(width: 2000),
          s2400: url @transform(width: 2400),
          s2800: url @transform(width: 2800),
          s3200: url @transform(width: 3200)
        }
      }
    }
  }
`;


export const FRAGMENT_L2_FIELDS = gql`
  fragment introModuleL2 on introductionModuleL2_introductionL2_BlockType {
    __typename
    introductionTitleL2
    introductionBylineL2
  }

  fragment inlineImagesModuleL2 on contentModules_InlineImages_BlockType {
    __typename
    images{
      __typename
      ...on images_BlockType{
        inlineImage{
          url,
          width,
          height,
          focalPoint,title,
          mimeType,
          s400: url @transform(width: 400),
      s800: url @transform(width: 800),
      s1200: url @transform(width: 1200),
      s1600: url @transform(width: 1600),
      s2000: url @transform(width: 2000),
      s2400: url @transform(width: 2400),
      s2800: url @transform(width: 2800),
      s3200: url @transform(width: 3200)
        },
        inlineImageCaption
      }
    }
  }

  fragment linkGroupModuleL2 on contentModules_LinkGroup_BlockType {
    linkSet{
      __typename
      ...on linkSet_BlockType{
        linkSetTitle,
        linkSetByline,
        linkSetURL { id, title, slug, sectionHandle, url},
        linkSetImage{
          url,
          width,
          height,
          focalPoint,title,
          mimeType,
          s400: url @transform(width: 400),
      s800: url @transform(width: 800),
      s1200: url @transform(width: 1200),
      s1600: url @transform(width: 1600),
      s2000: url @transform(width: 2000),
      s2400: url @transform(width: 2400),
      s2800: url @transform(width: 2800),
      s3200: url @transform(width: 3200)
        }
      }
    }
  }

  fragment wysiwygModuleL2 on contentModules_Wysiwyg_BlockType{
    __typename
    wysiwygContent
  }

  fragment accordionModuleL2 on contentModules_Accordion_BlockType {
    __typename
    groupTitle,
    groupByline
    items{
      __typename
      ...on items_BlockType{
        itemTitle,
        itemContent
      }
    }
  }

  fragment staffModuleL2 on contentModules_StaffList_BlockType {
    __typename
    staffIntroduction,
    staff{
      ... on people_people_Entry{
        peopleTitle,
        jobTitle,
        firstName,
        lastName,
        qualification,
        externalLink,
        studentProfile
      }
    }
  }

  fragment usefulLinksL2 on usefulLinks_usefulLinks_BlockType{
    __typename
    linkTitle, linkExternal, existingPage{
      slug, sectionHandle
    }
  }
    
  fragment downloadsL2 on downloads_downloads_BlockType{
    __typename
    title: downloadTitle, 
    downloadFile{url,kind,size
    }
  }
  
  fragment relatedLinksL2 on EntryInterface{
    __typename
    title, slug, sectionHandle, parent {title, sectionHandle}
  }
  
  fragment contactFormL2 on contactForm_ContactForm_BlockType{
    __typename
    id
    formByline
  }
`;


export const FRAGMENT_ARTICLE_FIELDS = gql`
  fragment linkGroupModuleArticle on contentModules_LinkGroup_BlockType {
    linkSet{
      __typename
      ...on linkSet_BlockType{
        linkSetTitle,
        linkSetByline,
        linkSetURL { id, title, slug, sectionHandle, url},
        linkSetImage{
          url,
          width,
          height,
          focalPoint,title,
          mimeType,
          s400: url @transform(width: 400),
      s800: url @transform(width: 800),
      s1200: url @transform(width: 1200),
      s1600: url @transform(width: 1600),
      s2000: url @transform(width: 2000),
      s2400: url @transform(width: 2400),
      s2800: url @transform(width: 2800),
      s3200: url @transform(width: 3200)
        }
      }
    }
  }

  fragment wysiwygModuleArticle on contentModules_Wysiwyg_BlockType{
    __typename
    wysiwygContent
  }


  fragment inlineImagesModuleArticle on contentModules_InlineImages_BlockType {
    __typename
    images{
      __typename
      ...on images_BlockType{
        inlineImage{
          url,
          width,
          height,
          focalPoint,title,
          mimeType,
         s400: url @transform(width: 400),
      s800: url @transform(width: 800),
      s1200: url @transform(width: 1200),
      s1600: url @transform(width: 1600),
      s2000: url @transform(width: 2000),
      s2400: url @transform(width: 2400),
      s2800: url @transform(width: 2800),
      s3200: url @transform(width: 3200)
        },
        inlineImageCaption
      }
    }
  }

  fragment accordionModuleArticle on contentModules_Accordion_BlockType {
    __typename
    groupTitle,
    groupByline
    items{
      __typename
      ...on items_BlockType{
        itemTitle,
        itemContent
      }
    }
  }

  fragment QuoteArticle on contentModules_Quote_BlockType{
    quote, cite
  }

  fragment contactFormArticle on contactForm_ContactForm_BlockType{
    __typename
    id
    formByline
  }
`;