<template>
  <content-wrapper id="search" v-if="data.results">
    <template v-slot:header>
      <introduction :data="data.entry"></introduction>
    </template>

    <ltl-search></ltl-search>
   
  </content-wrapper>
</template>



<script>
  import ContentWrapper from '~/components/ContentWrapper.vue'
  import Introduction from '~/components/Introduction.vue'
  import GlobalSearch from '~/components/search/GlobalSearch.vue'
  import LtlSearch from '~/components/search/LtlSearch.vue'
  import gql from 'graphql-tag'

  export default {
    components: { ContentWrapper, Introduction, GlobalSearch, LtlSearch },
    data(){
      return {
        data: {
          entry: Object,
          results: false,
        },
      }
    },
    apollo: {
      entry: {
        query: gql`
            query variables($slug: [String!]){
              entry(slug: $slug) {
                id,
                title,
                ...on ourResearchChildren_ourResearch_Entry {
                  introductionModuleL2{
                    ...on introductionModuleL2_introductionL2_BlockType{
                      introductionTitleL2,
                      introductionBylineL2
                    }
                  }
                }
              }
            }
          `,
          variables() {   
            switch(this.$route.name){
              case 'ourResearch.ourResearchData':
                this.slug = 'research-data'
              break;
              case 'ourResearch.ourResearchPublications':
                this.slug = 'publications'
              break;
            }

            return {
              slug: this.slug
            }
          },
          result ({ data, loading, networkStatus }) {
            this.$store.commit('setTitle',data.entry.title)
            this.data.entry = data.entry
            this.$store.commit('setLoaded',true)
            this.data.results = true
            if (this.$store.state.graphQLDebug){
              console.log('Full graphQL entry data:')
              console.log(data.entry)
            }
          }
      },
    },
    computed: {
    },
    methods: {

    },
  }
</script>