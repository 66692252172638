<template>
  <content-wrapper id="search" :class="data.slug" v-if="data.results">
    <template v-if="notGlobal" v-slot:header>
      <introduction :data="data.entry"></introduction>
    </template>

    <template v-if="isGlobal">
      <global-search>
        <filter-bar></filter-bar>
        <status-bar v-if="!data.loading" :batch-data="batchData" :reset="showReset" @reset="setDefaultFilters()" :total="data.total"></status-bar>
      </global-search>
      <template v-if="data.loading">
        <div class="no-results">
          <p class="strong big d-blue">Loading...</p>
        </div>
      </template>
      <global-search-results v-if="!data.loading" :data="results" :loaded="data.results"></global-search-results>
    </template>
   
    <template v-if="isResearchers">
      <filter-bar></filter-bar>
      <status-bar v-if="!data.loading" :batch-data="batchData" :reset="showReset" @reset="setDefaultFilters()" :total="data.total"></status-bar>
      <template v-if="data.loading">
        <div class="no-results">
          <p class="strong big d-blue">Loading...</p>
        </div>
      </template>
      <researcher-supervisor-results v-if="!data.loading" :data="results" :loaded="data.results"></researcher-supervisor-results>
    </template>

    <template v-if="isGeneral">
      <filter-bar></filter-bar>
      <status-bar v-if="!data.loading" :batch-data="batchData" :reset="showReset" @reset="setDefaultFilters()" :total="data.total"></status-bar>
      <template v-if="data.loading">
        <div class="no-results">
          <p class="strong big d-blue">Loading...</p>
        </div>
      </template>
      <results v-if="!data.loading" :data="results" :loaded="data.results"></results>
    </template>

    <pagination v-if="checkResultsLoaded" :data="data"></pagination>

  </content-wrapper>
</template>

<script>
  import ContentWrapper from '~/components/ContentWrapper.vue'
  import Introduction from '~/components/Introduction.vue'
  import GlobalSearch from '~/components/search/GlobalSearch.vue'
  import FilterBar from '~/components/search/FilterBar.vue'
  import StatusBar from '~/components/search/StatusBar.vue'
  import Results from '~/components/search/Results.vue'
  import PublicationsResults from '~/components/search/PublicationsResults.vue'
  import ResearcherSupervisorResults from '~/components/search/ResearcherSupervisorResults.vue'
  import GlobalSearchResults from '~/components/search/GlobalSearchResults.vue'
  import Pagination from '~/components/search/Pagination.vue'
  import gql from 'graphql-tag'

  export default {
    components: { ContentWrapper, Introduction, GlobalSearch, FilterBar, StatusBar, Results, PublicationsResults, ResearcherSupervisorResults, GlobalSearchResults, Pagination },
    data(){
      return {
        data: {
          entry: Object,
          loading: false,
          results: false,
          searchResult: false,
          overlayLoad: false,
          pageResult: false,
          skipQuery: false,
          orderby: '',
          entries: [],
          slug: '',
          total: 0,
          relatedToVal: [],
          slug: this.$route.name,
          searchSettings: {
            search: true,
            filters: {
            },
            orderBy: ['Relevance','Posted date','First online date','Acceptance date','Publication date'],
          }
        },
        results: {
          total: 144,
          payload: [] 
        },
        // Non API data from here down 
        batchData: { 
          default: 12,
          options: [12,24,48],
        },
        showReset: false,
        filterChange: false
      }
    },
    apollo: {
      entry: {
        query: gql`
            query variables($slug: [String!]){
              entry(slug: $slug) {
                slug,
                title,
                ...on searchIndex_searchIndex_Entry{
                  introductionModuleL2{
                    ...on introductionModuleL2_introductionL2_BlockType{
                      introductionTitleL2,
                      introductionBylineL2
                    }
                  }
                },
                ...on postgraduateStudyChildren_postgraduateStudyChildren_Entry {
                  introductionModuleL2{
                    ...on introductionModuleL2_introductionL2_BlockType{
                      introductionTitleL2,
                      introductionBylineL2
                    }
                  }
                },
                ...on ourResearchChildren_ourResearch_Entry {
                  introductionModuleL2{
                    ...on introductionModuleL2_introductionL2_BlockType{
                      introductionTitleL2,
                      introductionBylineL2
                    }
                  }
                },
                ...on updatesIndex_updatesIndex_Entry {
                  introductionModuleL2{
                    ...on introductionModuleL2_introductionL2_BlockType{
                      introductionTitleL2,
                      introductionBylineL2
                    }
                  }
                },
                ...on eventsIndex_eventsIndex_Entry {
                  introductionModuleL2{
                    ...on introductionModuleL2_introductionL2_BlockType{
                      introductionTitleL2,
                      introductionBylineL2
                    }
                  }
                }
                ...on findAResearcherSupervisor_findAResearcherSupervisor_Entry {
                  introductionModuleL2{
                    ...on introductionModuleL2_introductionL2_BlockType{
                      introductionTitleL2,
                      introductionBylineL2
                    }
                  }
                }
              },
              discovery: categories {
                ... on discoveryDepartments_Category {
                  title, slug
                }
              }
              departments: entries(section: "faculties",orderBy: "title"){  
                id, sectionHandle title slug uri 
              },
              categories: categories {
                ... on researchAreas_Category {
                  id, title, slug
                }
              }
            }
          `,
          variables() {     
            switch(this.$route.name){
              case 'postgraduateStudy.studentProfiles':
                this.slug = 'student-profiles'
              break;
              case 'ourResearch.ourResearchCaseStudies':
                this.slug = 'case-studies'
              break;
              case 'updatesIndex':
                this.slug = 'updates'
              break;
              case 'eventsIndex':
                this.slug = 'events'
              break;
              case 'eventsIndex':
                this.slug = 'events'
              break;
              case 'findAResearcherSupervisor':
                this.slug = 'find-a-researcher-supervisor'
              break;
              case 'searchIndex':
                this.slug = 'search'
              break;
            }

            return {
              slug: this.slug
            }
          },
          result ({ data, loading, networkStatus }) {
            
            this.$store.commit('setTitle',data.entry.title)
            
            if (this.$store.state.graphQLDebug){
              //console.log('Full graphQL departments data:')
              //console.log(data.departments)
            }

            this.$store.commit('setDepartments', data.departments)
            //console.log('Full graphQL categories data:')

            let departmentIDSet = []
            data.departments.forEach(function (value) {
              //console.log(value.id)
              departmentIDSet.push(value.id)
            });
            this.$store.commit('setDepartmentsIDSet', departmentIDSet)


            //console.log(data.categories)
            this.$store.commit('setCategories', data.categories)

            let categoryIDSet = []
            data.categories.forEach(function (value) {
              //console.log(value.id)
              categoryIDSet.push(value.id)
            });
            this.$store.commit('setCategoriesIDSet', categoryIDSet)


            this.data.entry = data.entry


            let discoverySet = []
            data.discovery.forEach(function (value) {
              if (typeof(value.title) !== 'undefined')
                discoverySet.push(value)
             });

            this.$store.commit('setDiscoveryDepartments', discoverySet)

            this.data.entry = data.entry
            this.$store.commit('setLoaded',true)
            this.data.results = true

            // output full graphQL result for debug
            if (this.$store.state.graphQLDebug){
              console.log('Full graphQL entry data:')
              console.log(data.entry)
            }

            this.setup()
          }
      },
      studentprofiles: {
        query: gql`query variables($section: [String!],
                        $keywords: String!,
                        $relatedTo: [Int] = [],
                        $limit: Int!,
                        $offset: Int!,
                        $orderBy: String!){
                  studentprofiles: entries(section:    $section,
                  search:     $keywords,
                  relatedTo:  $relatedTo,
                  limit:      $limit, 
                  offset:     $offset,
                  orderBy:    $orderBy){
                    id, 
                    slug,
                    sectionHandle,
                    title,
                    ... on studentProfilesChildren_studentProfiles_Entry{
                      personLink{
                        ... on people_people_Entry{
                          jobTitle,
                          firstName,
                          lastName,
                          qualification
                        }
                      },
                      departments{
                        title
                      },
                      heroImage: profileImage{
                        url,
                        width,
                        height,title,
                        focalPoint,
                        mimeType,
                        s400: url @transform(width: 400),
                        s800: url @transform(width: 800),
                        s1200: url @transform(width: 1200),
                        s1600: url @transform(width: 1600),
                        s2000: url @transform(width: 2000),
                        s2400: url @transform(width: 2400),
                        s2800: url @transform(width: 2800),
                        s3200: url @transform(width: 3200)
                      }
                    }
                  },
                  total: entryCount(section: $section,
                                    search: $keywords,
                                    relatedTo: $relatedTo,
                                    )
                }`,
          variables() {
            //console.log('dept param: ' + this.$store.state.activeFilters.department)
            let relatedTo = []

            if (this.$store.state.activeFilters.department !== ''){
              relatedTo = this.$store.state.activeFilters.department
            }

            let offSetIndex = (this.$store.state.activeFilters.pagination-1)*this.$store.state.activeFilters.batchSize

            this.data.loading = true
            return {
              section:    this.$route.name.split('.').pop()+'Children',
              keywords:   this.$store.state.activeFilters.search,
              relatedTo:  relatedTo,
              limit:      this.$store.state.activeFilters.batchSize,
              offset:     offSetIndex,
              orderBy:    'title' // fixed
            }
          },
          // Disables the query on component load
          skip() {
            return this.skipQuery
          },
          result ({ data, loading, networkStatus }) {  
            this.data.loading = false
            this.data.entries = data.studentprofiles
            this.results.payload = data.studentprofiles

            // output full graphQL result for debug
            if (this.$store.state.graphQLDebug){
              console.log('Full graphQL entries data:')
              console.log(data.studentprofiles)
              console.log('Full graphQL total:')
              console.log(data.total)
            }

            //this.$store.commit('setResults',this.results)
            //this.data.searchResult = true;
            
            // console.log('search triggered')
            // console.log(this.$store.state.activeFilters)

            
            // // output full graphQL result for debug
            // console.log('Full graphQL entries data:')
            // console.log(data.studentprofiles)
            // console.log('Full graphQL total:')
            // console.log(data.total)
            this.data.total = data.total
            this.results.payload = data.studentprofiles
            this.results.total = data.total
            this.data.searchResult = true;
            this.$store.commit('setLoaded',true)
            this.data.result = true
          }
      },
      researchers: {
        query: gql`query variables($section: [String!],
                        $keywords: String!,
                        $discoveryDepartment: [QueryArgument!],
                        $limit: Int!,
                        $offset: Int!){
                  researchers: entries(section: $section,
                  search:     $keywords,
                  limit:      $limit, 
                  offset:     $offset,
                  orderBy:    "lastName",
                  discoveryDepartment: $discoveryDepartment,
                  discoveryLink: ":notempty:"){
                    slug,
                    sectionHandle,
                    title,
                    ... on researchers_researchers_Entry{
                      jobTitle,
                      firstName,
                      lastName,
                      jobTitle,
                      discoveryLink,
                      discoveryDepartment,
                      email,
                      phone: peoplePhone,
                      qualification
                    }
                  },
                  total: entryCount(section:    $section,
                                  search:     $keywords,
                                  discoveryLink: ":notempty:",
                                  discoveryDepartment: $discoveryDepartment
                            )

                }`,
          variables() {
            let discoveryDepartment = ':notempty:'

            if (this.$store.state.activeFilters.Department !== ''){
              discoveryDepartment = this.$store.state.activeFilters.Department
              //console.log('selected department: ' + this.$store.state.activeFilters.Department)
            }

             if (typeof(discoveryDepartment) === 'undefined')
               discoveryDepartment = ':notempty:'

            let offSetIndex = (this.$store.state.activeFilters.pagination-1)*this.$store.state.activeFilters.batchSize

            //console.log('detected discovery department ' + discoveryDepartment)


            this.data.loading = true
            return {
              section:              'researchers',
              keywords:             this.$store.state.activeFilters.search,
              discoveryDepartment:  discoveryDepartment,
              limit:                this.$store.state.activeFilters.batchSize,
              offset:               offSetIndex,
            }
          },
          // Disables the query on component load
          skip() {
            return this.skipQuery
          },
          result ({ data, loading, networkStatus }) {
            console.log(data)
            this.data.loading = false
            this.data.entries = data.researchers
            this.results.payload = data.researchers
            // output full graphQL result for debug
            if (this.$store.state.graphQLDebug){
              console.log('Full graphQL entries data:')
              console.log(data.researchers)
              console.log('Full graphQL total:')
              console.log(data.total)
              console.log('payload')
              console.log(this.results.payload)
            }
            this.data.total = data.total
            this.results.payload = data.researchers
            this.results.total = data.total
            this.data.searchResult = true;
            this.$store.commit('setLoaded',true)
            this.data.result = true
                    }
      },
      casestudies: {
          query: gql`query variables($section: [String!],
                                      $keywords: String!,
                                      $relatedTo: [Int] = [],
                                      $limit: Int!,
                                      $offset: Int!,
                                      $orderBy: String!){
                        casestudies: entries(section:    $section,
                                search:     $keywords,
                                relatedTo:  $relatedTo,
                                limit:      $limit, 
                                offset:     $offset,
                                orderBy:    $orderBy){
                                  id, 
                                  slug,
                                  sectionHandle,
                                  title,

                                  ... on caseStudies_caseStudies_Entry{
                                    publishedDate,
                                    articleIntroduction,
                                    departments{
                                      title
                                    },
                                    heroImage{
                                      url,
                                      width,title,
                                      height,
                                      focalPoint,
                                      mimeType,
                                      s400: url @transform(width: 400),
                                      s800: url @transform(width: 800),
                                      s1200: url @transform(width: 1200),
                                      s1600: url @transform(width: 1600),
                                      s2000: url @transform(width: 2000),
                                      s2400: url @transform(width: 2400),
                                      s2800: url @transform(width: 2800),
                                      s3200: url @transform(width: 3200)
                                    }
                                  }
                                },
                                total: entryCount(section: $section,
                                        search: $keywords,
                                        relatedTo: $relatedTo,
                                        )
                    }`,
          variables() {
            
            let offSetIndex = (this.$store.state.activeFilters.pagination-1)*this.$store.state.activeFilters.batchSize
            let relatedTo = []
            if (this.$store.state.activeFilters.department !== ''){
              relatedTo = this.$store.state.activeFilters.department
            }


            //console.log('dept param: ' + this.$store.state.activeFilters.department)
            if (this.$store.state.activeFilters.department !== '')
              this.data.relatedToVal = this.$store.state.activeFilters.department
            
            //console.log('detected orderby: ' + this.$store.state.activeFilters.orderBy)
            switch(this.$store.state.activeFilters.orderBy){
              case 'relevance':
                this.data.orderBy = 'score'
              break;
              case 'publication-date':
                this.data.orderBy = 'publishedDate DESC'
              break;
              default:
                this.data.orderBy = this.$store.state.activeFilters.orderBy
              break;
            }

            this.data.loading = true

            return {
              section:    'caseStudies',
              keywords:   this.$store.state.activeFilters.search,
              relatedTo:  relatedTo,
              limit:      this.$store.state.activeFilters.batchSize,
              offset:     offSetIndex,
              orderBy:    this.data.orderBy
            }
          },
          // Disables the query on component load
          skip() {
            return this.skipQuery
          },
          result ({ data, loading, networkStatus }) {  
            this.data.loading = false
            this.data.entries = data.casestudies
            this.results.payload = data.casestudies
            this.results.total = data.total

            if (this.$store.state.graphQLDebug){
              console.log('Full graphQL entries data:')
              console.log(data.casestudies)
              console.log('Full graphQL total:')
              console.log(data.total)
            }

            this.data.total = data.total
            this.results.payload = data.casestudies
            this.results.total = data.total
            this.data.searchResult = true;
            this.$store.commit('setLoaded',true)
            this.data.result = true
          }
      },
      events: {
          query: gql`query variables($section: [String!],
                                      $relatedTo: [Int] = [],
                                      $limit: Int!,
                                      $offset: Int!,
                                      $orderBy: String!){
              events: entries(section:    $section,
                      relatedTo: $relatedTo,
                      limit:      $limit, 
                      offset:     $offset,
                      orderBy:    $orderBy){
                        id, 
                        slug,
                        sectionHandle,
                        title,
                        ...on events_events_Entry {
                          byline: eventTime,
                          byline2: eventDate @formatDateTime (format: "d M Y"),
                          heroImage{
                            url,
                            width,
                            height,title,
                            focalPoint,
                            mimeType,
                            s400: url @transform(width: 400),
                            s800: url @transform(width: 800),
                            s1200: url @transform(width: 1200),
                            s1600: url @transform(width: 1600),
                            s2000: url @transform(width: 2000),
                            s2400: url @transform(width: 2400),
                            s2800: url @transform(width: 2800),
                            s3200: url @transform(width: 3200)
                          }
                        }
                      },
                      total: entryCount(section: $section,
                                        relatedTo: $relatedTo)
                    }`,
          variables() {
            
            switch(this.$store.state.activeFilters.orderBy){
              default:
                this.data.orderBy = 'eventDate DESC'
              break;
            }

            let departmentsVal = []

            if (this.$store.state.activeFilters.department != '')
              departmentsVal.push(this.$store.state.activeFilters.department)

            let categoriesVal = []
            
            if (this.$store.state.activeFilters.category != '')
              categoriesVal.push(this.$store.state.activeFilters.category)

            let relatedToVal = [];
            if (departmentsVal.length>0)
              relatedToVal.push(departmentsVal[0])
            if (categoriesVal.length>0)
              relatedToVal.push(categoriesVal[0])

            let offSetIndex = (this.$store.state.activeFilters.pagination-1)*this.$store.state.activeFilters.batchSize
            this.data.loading = true
            return {
              section:    'events',
              relatedTo:  relatedToVal,
              limit:      this.$store.state.activeFilters.batchSize,
              offset:     offSetIndex,
              orderBy:    this.data.orderBy
            }
          },
          // Disables the query on component load
          skip() {
            return this.skipQuery
          },
          result ({ data, loading, networkStatus }) {  
            this.data.loading = false
            this.data.entries = data.events
            this.results.payload = data.events
            this.results.total = data.total

            if (this.$store.state.graphQLDebug){
              console.log('Full graphQL entries data:')
              console.log(data.events)
              console.log('Full graphQL total:')
              console.log(data.total)
            }

            //this.$store.commit('setResults',this.results)
            
            // console.log('search triggered')


            // console.log(this.$store.state.activeFilters)
            
            // console.log(this.batchData)


            // // output full graphQL result for debug
            // console.log('Full graphQL entries data:')
            // console.log(data.events)
            // console.log('Full graphQL total:')
            // console.log(data.total)
            this.data.total = data.total
            this.results.payload = data.events
            this.results.total = data.total
            this.data.searchResult = true;
            this.$store.commit('setLoaded',true)
            this.data.result = true
          }
      },
      updates: {
          query: gql`query variables($section: [String!],
                                      $relatedTo: [Int] = [],
                                      $limit: Int!,
                                      $offset: Int!,
                                      $orderBy: String!){
              updates: entries(section:    $section,
                      relatedTo: $relatedTo,
                      limit:      $limit, 
                      offset:     $offset,
                      orderBy:    $orderBy){
                        id, 
                        slug,
                        sectionHandle,
                        title,
                        ...on updates_updates_Entry{
                          byline: publishedDate @formatDateTime (format: "d M Y"),
                          departments{
                            title
                          },
                          heroImage{
                            url,
                            width,
                            height,title,
                            focalPoint,
                            mimeType,
                            s400: url @transform(width: 400),
                            s800: url @transform(width: 800),
                            s1200: url @transform(width: 1200),
                            s1600: url @transform(width: 1600),
                            s2000: url @transform(width: 2000),
                            s2400: url @transform(width: 2400),
                            s2800: url @transform(width: 2800),
                            s3200: url @transform(width: 3200)
                          }
                        }
                      },
                      total: entryCount(section: $section,
                                        relatedTo: $relatedTo)
                    }`,
          variables() {
            let departmentsVal = []

            if (this.$store.state.activeFilters.department != '')
              departmentsVal.push(this.$store.state.activeFilters.department)

            let categoriesVal = []
            
            if (this.$store.state.activeFilters.category != '')
              categoriesVal.push(this.$store.state.activeFilters.category)

            let relatedToVal = [];
            if (departmentsVal.length>0)
              relatedToVal.push(departmentsVal[0])
            if (categoriesVal.length>0)
              relatedToVal.push(categoriesVal[0])

            let offSetIndex = (this.$store.state.activeFilters.pagination-1)*this.$store.state.activeFilters.batchSize
            this.data.loading = true
            return {
              section:    'updates',
              relatedTo:  relatedToVal,
              limit:      this.$store.state.activeFilters.batchSize,
              offset:     offSetIndex,
              orderBy:    'publishedDate desc'
            }
          },
          // Disables the query on component load
          skip() {
            return this.skipQuery
          },
          result ({ data, loading, networkStatus }) { 
            this.data.loading = false
            this.results.payload = data.updates
            this.results.total = data.total
            if (this.$store.state.graphQLDebug){
              console.log('Full graphQL entries data:')
              console.log(data.updates)
              console.log('Full graphQL total:')
              console.log(data.total)
            }

            //this.$store.commit('setResults',this.results)

            // console.log('search triggered')
            // console.log(this.$store.state.activeFilters)
            // console.log(this.batchData)

            // // output full graphQL result for debug
            // console.log('Full graphQL entries data:')
            // console.log(data.updates)
            // console.log('Full graphQL total:')
            // console.log(data.total)
            this.data.total = data.total
            this.results.payload = data.updates
            this.results.total = data.total
            this.data.searchResult = true;
            this.$store.commit('setLoaded',true)
            this.data.result = true
          }
      },
      globalresults: {
        query: gql`query variables($keywords: String!,
                            $section: [String]!,
                            $limit: Int!,
                            $offset: Int!,
                            $orderBy: String!) {
                              globalresults: entries(search: $keywords,section: $section,limit: $limit, offset: $offset, orderBy: $orderBy) {
                                id
                                slug
                                sectionHandle
                                title
                                parent{
                                  id, title, slug, sectionHandle
                                }
                                ... on researchers_researchers_Entry{
                                  firstName,
                                  lastName,
                                  discoveryLink,
                                  discoveryDepartment,
                                }
                              }
                              total: entryCount(search: $keywords, section: $section)
                            }`,
          variables() {
            let batchSize = 12
            if (this.$store.state.activeFilters.batchSize)
              batchSize = this.$store.state.activeFilters.batchSize
            let orderBy = 'score'
            if (this.$store.state.activeFilters.orderBy){
              if (this.$store.state.activeFilters.orderBy == 'relevance')
                orderBy = 'score'
              else
                orderBy = this.$store.state.activeFilters.orderBy
            }
            let offSetIndex = (this.$store.state.activeFilters.pagination-1)*batchSize
            let search = ''
            if (this.data.overlayLoad)
              search = this.$store.state.activeFilters.search
            else
              search = this.data.searchSettings.filters.search
            // reset mode
            if (!this.data.overlayLoad)
              this.data.overlayLoad = false
            this.data.loading = true
            return {
              section: ["ourResearchChildren","testingAnalyticalServicesChildren","industryChildren","postgraduateStudyChildren","caseStudies","contactIndex","disclaimer","events","eventsIndex","faculties","industryPartnershipsIndex","ourResearchIndex","postgraduateStudyIndex","privacyPolicy","studentProfilesChildren","testingAnalyticalServicesIndex","updatesIndex","updates","researchers"],
              keywords:   search,
              limit:      batchSize,
              offset:     offSetIndex,
              orderBy:    orderBy
            }
          },
          // Disables the query on component load
          skip() {
            return this.skipQuery
          },
          result ({ data, loading, networkStatus }) {
            this.data.loading = false
            this.data.total = data.total
            this.results.total = data.total
            this.data.entries = data.globalresults
            this.data.searchResult = true;
            this.data.globalLoad = true
            this.results.payload = data.globalresults
            if (this.$store.state.graphQLDebug){
              console.log('Full graphQL entries data:')
              console.log(data.globalresults)
              console.log('Full graphQL total:')
              console.log(data.total)
            }
            //this.$store.commit('setResults',this.results)
            //this.data.searchResult = true;
            
            // console.log('search triggered')
            // console.log(this.$store.state.activeFilters)

            
            // // output full graphQL result for debug
            // console.log('Full graphQL entries data:')
            // console.log(data.globalresults)
            // console.log('Full graphQL total:')
            //console.log(data.total)
            this.data.total = data.total
            this.results.payload = data.globalresults
            this.$store.commit('setLoaded',true)
            this.data.result = true
          }
      },
    },
    computed: {
      filters() {
        return this.$store.state.activeFilters
      },
      batchSize() {
        return this.$store.state.activeFilters.batchSize
      },
      isGlobal() {
        return this.$route.name === 'searchIndex'
      },
      isResearchers() {
        return this.$route.name === 'findAResearcherSupervisor'
      },
      isGeneral() {
        return this.$route.name !== 'findAResearcherSupervisor' && this.$route.name !== 'searchIndex'
      },
      notGlobal() {
        return this.$route.name !== 'searchIndex'
      },
      checkResultsLoaded(){
        return this.data.searchResult
      }
    },
    methods: {
      setDefaultFilters() {

        let filterDefaults = {}
        let data = this.data.searchSettings

        if (!this.data.overlayLoad){
          if(this.$store.state.activeFilters !== null){
            if (this.$store.state.activeFilters.globalSearch != ''){
              filterDefaults.search = this.$store.state.activeFilters.globalSearch
              filterDefaults.globalSearch = this.$store.state.activeFilters.globalSearch
              this.data.overlayLoad = true // need state awareness of this condition otherwise filter watch squashes this change
            }
            else{
              if (!this.data.overlayLoad)
                filterDefaults.search = ''
            }
          }
          else{
            if (!this.data.overlayLoad)
              filterDefaults.search = ''
          }

          filterDefaults.globalSearch = ""
          filterDefaults.batchSize = this.batchData.default
          filterDefaults.orderBy = this.$toKebab(data.orderBy[0])
          filterDefaults.pagination = 1

          // inject unknowns
          Object.keys(data.filters).forEach(function (key) {
            filterDefaults[key] = ""
          });

          // commit (watch will scroll up)
          this.$setActiveFilters(filterDefaults)
          this.$nextTick(() => {
            this.showReset = this.isGlobal
          });
        }
        
        let route = this.$route.name

       if(route === 'postgraduateStudy.studentProfiles') {
        this.data.searchSettings.orderBy = ['Relevance','Lastname','Firstname']
       }

      },
      getResults() {
        
        this.data.searchResult = false;
       
        let results = []

        // start example 
        let dummyResult = {}
        let route = this.$route.name

        this.$store.commit('setCraftPayload',this.data)


        //console.log('search triggered what are our filters')
        //console.log(this.$store.state.activeFilters)

        if(route === 'postgraduateStudy.studentProfiles') {
          this.data.searchSettings.filters['department'] = this.$store.state.departments
          this.data.searchSettings.search = true;
          this.data.searchSettings.orderBy = ['Name']
          this.$apollo.queries.studentprofiles.skip = false
          this.$apollo.queries.studentprofiles.refetch()
        }

        if(route === 'ourResearch.ourResearchCaseStudies') {
          this.data.searchSettings.filters['department'] = this.$store.state.departments
          this.data.searchSettings.search = true;
          this.data.searchSettings.orderBy = ['Publication date','Relevance','Title']
          if (typeof(this.$apollo.queries.casestudies) !== 'undefined'){
            this.$apollo.queries.casestudies.skip = false
            this.$apollo.queries.casestudies.refetch()
          }
        }

        if(route === 'eventsIndex') {
          this.data.searchSettings.filters['department'] = this.$store.state.departments
          this.data.searchSettings.filters['category'] = this.$store.state.categories
          this.data.searchSettings.search = false
          this.data.searchSettings.orderBy = ['Most recent']
          this.$apollo.queries.events.skip = false
          this.$apollo.queries.events.refetch()
        }

        if(route === 'updatesIndex') {
          this.data.searchSettings.filters['department'] = this.$store.state.departments
          this.data.searchSettings.filters['category'] = this.$store.state.categories
          this.data.searchSettings.search = false
          this.data.searchSettings.orderBy = ['Most recent']
          this.$apollo.queries.updates.skip = false
          this.$apollo.queries.updates.refetch()
        }

        if(route === 'searchIndex') {
          if (!this.data.overlayLoad)
            this.data.searchSettings.filters['search'] = this.$store.state.activeFilters.search
          else
            this.data.searchSettings.filters['search'] = this.$store.state.activeFilters.globalSearch
          this.data.searchSettings.search = true
          this.data.searchSettings.orderBy = ['Relevance','Title']
          if (typeof(this.$apollo.queries.globalresults) !== 'undefined'){
            this.$apollo.queries.globalresults.skip = false
            this.$apollo.queries.globalresults.refetch()
          }
        }

        if(route === 'findAResearcherSupervisor') {
          this.data.searchSettings.filters['Department'] = this.$store.state.discoveryDepartments
          this.data.searchSettings.search = true;
          this.data.searchSettings.orderBy = ['lastName']
          if (typeof(this.$apollo.queries.globalresults) !== 'undefined'){
            this.$apollo.queries.researchers.skip = false
            this.$apollo.queries.researchers.refetch()
          }
        }

      },
      setup() {
        this.setDefaultFilters()
        this.getResults()
      }
    },
    watch: {  
      filters: {
        handler(val, oldVal){
          if(val && oldVal) {
            // find out what changed
            let diff = Object.keys(val).filter(k => val[k] !== oldVal[k]);
            // console.log(diff)

            // show reset if something other than pagination has changed
            if(!diff.includes('pagination')) {
              this.showReset = true

              // reset pagination to 1 if something other than pagination has changed
              if(val.pagination !== 1) {
                this.$setActiveFilters({pagination: 1})
              }
            }
            
            if(diff.length) {
              if (this.data.globalLoad || this.$route.name != 'searchIndex'){
                this.getResults()
                // this.$scrollWithOffset('.cw-primary')
              }

            }
          }
        },
        deep: true
      },
    },
    created() {
      this.setup()

    }
  }
</script>