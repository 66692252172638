export default {
  state: {
    loaded: false,
    craftLoaded: false,
    breakpoint: '',
    fullURL: process.env.BASE_URL,
    defaultTitle: 'Lincoln University Research',
    title: '',
    parentTitle: '',
    parentSectionHandle: '',
    parentSlug: '',
    description: 'Lincoln University undertake research and grow the knowledge of students, shaping a world that benefits from a greater understanding of the relationship between our land, the food and experiences created from it.',
    menu: false, // used for overlay status
    search: false, // used for overlay status
    breadcrumb: false,
    craftPayload: null,
    activeFilters: null,
    results: null,
    popstate: false,
    lastRoute: null,
    graphQLDebug: false,
  },
  mutations: {
    setLoaded (state, payload){
      state.loaded = payload
    },
    setCSRFTokenName (state, payload){
      state.csrfTokenName = payload
    },
    setCSRFToken (state, payload){
      state.csrfToken = payload
    },
    setBreakpoint (state, payload){
      state.breakpoint = payload
    },
    setMenu (state, payload){
      state.menu = payload
    },
    setSearch (state, payload){
      state.search = payload
    },
    setBreadcrumb (state, payload){
      state.breadcrumb = payload
    },
    setCraftPayload (state, payload){
      state.craftPayload = payload
    },
    setActiveFilters (state, payload){
      state.activeFilters = payload
    },
    setResults (state, payload){
      state.results = payload
    },
    setTitle (state, payload){
      state.title = payload
    },
    setPageID (state, payload){
      state.pageID = payload
    },
    setParentTitle (state, payload){
      state.parentTitle = payload
    },
    setParentSectionHandle (state, payload){
      state.parentSectionHandle = payload
    },
    setParentSlug (state, payload){
      state.parentSlug = payload
    },
    setTitle (state, payload){
      state.title = payload
    },
    setPopstate(state, payload){
      state.popstate = payload
    },
    setLastRoute(state, payload){
      state.lastRoute = payload
    },
    setCraftLoaded(state, payload){
      state.craftLoaded = payload
    },
    setDepartments(state, payload){
      state.departments = payload
    },
    setDepartmentsIDSet(state, payload){
      state.departmentsIDSet = payload
    },
    setCategories(state, payload){
      state.categories = payload
    },
    setDiscoveryDepartments(state, payload){
      state.discoveryDepartments = payload
    },
    setCategoriesIDSet(state, payload){
      state.categoriesIDSet = payload
    },
    setMenuEntries(state, payload){
      state.menuEntries = payload
    }
  },
  actions: {
  },
}