<template v-if="data.result">
  <ul v-if="pageCount > 1" id="pagination" class="grid gap-10">

    <li v-if="page !== 1" @click="prev" class="prev"></li>
    <li v-if="!showPrevDots" @click="page = 1" :class="{'active' : page === 1, 'hidden' : showPrevDots && isXs}" class="page">1</li>
    <li v-if="showPrevDots" class="dots" @click="page = 1"></li>

    <li v-if="pageCount > 2" 
        v-for="index in displayRange" 
        @click="page = index" 
        :class="{'active' : page === index}"
        class="page">
      {{index}}
    </li>

    <li v-if="showNextDots" class="dots" @click="page = pageCount"></li>
    <li v-if="!showNextDots" @click="page = pageCount" :class="{'active' : page === pageCount, 'hidden' : showNextDots && isXs}" class="page">{{pageCount}}</li>
    <li v-if="page !== pageCount" @click="next" class="next"></li>

  </ul>
</template>


<script>

  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
        showPrevDots: false,
        showNextDots: false
      }
    },
    computed: {
      page: {
        get() {
          return this.$store.state.activeFilters.pagination
        },
        set(value) {
          this.$setActiveFilters({pagination: value})
        }
      },
      batchSize() {
        return this.$store.state.activeFilters.batchSize
      },
      totalResults() {
        console.log('detected pages: ' + this.data.total)
        return this.data.total
      },
      pageCount() {
        return Math.ceil(this.data.total / this.batchSize)
      },
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      displayRange() {

        let range = (this.isXs) ? 2 : 4
        let min = 1
        let max = this.pageCount

        // customise array if we have a lot of results...
        if(this.pageCount > 11 || this.isXs) {
          min = (this.page - range > 1) ? this.page - range : 1
          max = (this.page + range <= this.pageCount) ? this.page + range : this.pageCount
          this.showNextDots = true
          this.showPrevDots = true

          // adjust for first pages
          if (this.page <= range) {
            max += (range - this.page) + 1
          }

          // adjust for last pages
          if (this.page >= this.pageCount - range) {
            min -= (this.page - this.pageCount) + range
          }

          // enable dots
          this.showPrevDots = (this.page > range + 1) 
          this.showNextDots = (this.page < this.pageCount - range)

        } else {
          // ensure we reset the dots incase batch size has changed
          this.showNextDots = false
          this.showPrevDots = false
        }

        // build array
        var result = [];
        for (var i = min; i <= max; i++) {
          result.push(i);
        }

        // trim first and last as they are hardcoded
        result.shift();
        result.pop();

        return result
      },
    },
    methods: {
      prev() {
        this.page -= 1
      },
      next() {
        this.page += 1
      },
    },
  }
</script>