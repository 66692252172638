
import L1 from './components/layouts/L1'
import L2 from './components/layouts/L2'
import Search from './components/layouts/Search'
import LtlSearch from './components/layouts/LtlSearch'
import Article from './components/layouts/Article'
import FinePrint from './components/layouts/FinePrint'

import Home from './components/pages/Home'
import StudentProfile from './components/pages/StudentProfile'
import Contact from './components/pages/Contact'
import Error404 from './components/pages/Error404'
import Error500 from './components/pages/Error500'

let hashes = window.location.search.slice(window.location.search.indexOf('?') + 1).split('&')
let params = {}
hashes.map(hash => {
  let [key, val] = hash.split('=')
  params[key] = decodeURIComponent(val)
})


//huh
const craftPreview = params['x-craft-preview']
const token = params['token']

let routes = [
    {
        path: '/',
        name: 'home',
        meta: { title: 'Lincoln University Research' },
        component: Home,
    },
    {
        path: '/find-a-researcher-supervisor',
        name: 'findAResearcherSupervisor',
        component: Search,
    },
    {
        path: '/student-profiles',
        name: 'student-profiles',
        component: Search,
    },
    {
        path: '/student-profiles/profile',
        name: 'student-profiles.profile',
        component: StudentProfile,
    },
    
    {
        path: '/disclaimer',
        name: 'disclaimer',
        component: FinePrint,
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: FinePrint,
    },
    {
        path: '/our-research',
        name: 'ourResearch',
        meta: { title: 'Our Research' },
        component: L1,
    },
    {
        path: '/our-research/case-studies',
        name: 'ourResearch.ourResearchCaseStudies',
        meta: { title: 'Case Studies' },
        component: Search,
    },
    {
        path: '/our-research/case-studies/:slug',
        name: 'ourResearch.ourResearchCaseStudies.caseStudiesChildren',
        component: Article,
    },
    {
        path: '/our-research/research-data',
        name: 'ourResearch.ourResearchData',
        meta: { title: 'Research Data' },
        component: LtlSearch,
    },
    {
        path: '/our-research/publications',
        name: 'ourResearch.ourResearchPublications',
        meta: { title: 'Publications' },
        component: LtlSearch,
    },
    {
        path: '/our-research/faculties-research-centres',
        name: 'ourResearch.facultiesResearchCentres',
        meta: { title: 'Faculties & Research Centres' },
        params: {parent: 'ourResearch'},
        component: L2,
        children: [
            {
                path: ':slug', 
                name: 'ourResearch.facultiesResearchCentres.L3',
                component: L2,
            },
        ]
    },
    {
        path: '/our-research/:slug',
        name: 'ourResearch.ourResearchChildren',
        params: {parent: 'ourResearch'},
        component: L2,
        children: [
            {
                path: ':L3slug',
                params: {parent: 'ourResearch.ourResearchChildren'}, 
                name: 'ourResearch.ourResearchChildren.L3',
                component: L2,
            },
        ]
    },
    {
        path: '/testing-analytical-services',
        name: 'testingAnalyticalServices',
        meta: { title: 'Testing & Analytical Services' },
        component: L1,
    },
    {
        path: '/testing-analytical-services/:slug',
        name: 'testingAnalyticalServices.testingAnalyticalServicesChildren',
        component: L2,
        children: [
            {
                path: ':L3slug', 
                name: 'testingAnalyticalServices.testingAnalyticalServicesChildren.L3',
                component: L2,
            },
        ]
    },
    {
        path: '/industry-partnerships',
        name: 'industry',
        meta: { title: 'Industry Partnerships' },
        component: L1,
    },
    {
        path: '/industry-partnership/:slug',
        name: 'industry.industryChildren',
        component: L2,
        children: [
            {
                path: ':L3slug', 
                name: 'industry.industryChildren.L3',
                component: L2,
            },
        ]
    },
    {
        path: '/postgraduate-study',
        name: 'postgraduateStudy',
        meta: { title: 'PostGraduate Study' },
        component: L1,
    },
    {
        path: '/postgraduate-study/student-profiles',
        name: 'postgraduateStudy.studentProfiles',
        meta: { title: 'Student Profiles' },
        component: Search,
    },
    {
        path: '/postgraduate-study/student-profiles/:slug',
        name: 'postgraduateStudy.studentProfiles.studentProfilesChildren',
        component: StudentProfile,
    },
    {
        path: '/postgraduate-study/:slug',
        name: 'postgraduateStudy.postgraduateStudyChildren',
        component: L2,
    },
    {
        path: '/updates',
        name: 'updatesIndex',
        meta: { title: 'Updates' },
        component: Search,
    },
    {
        path: '/updates/:slug',
        name: 'updatesIndex.updates',
        component: Article,
    },
    // {
    //     path: '/events',
    //     name: 'eventsIndex',
    //     meta: { title: 'Events' },
    //     component: Search,
    // },
    // {
    //     path: '/events/:slug',
    //     name: 'eventsIndex.events',
    //     component: Article,
    // },
    {
        path: '/contact',
        name: 'contactIndex',
        meta: { title: 'Contact' },
        component: Contact,
    },

    {
        path: '/search',
        name: 'searchIndex',
        meta: { title: 'Search' },
        component: Search,
    },
    { 
        path: "/error", 
        name: 'errorServer',
        component: Error500,
    },
    { 
        path: "*", 
        name: 'errorContent',
        component: Error404,
    }
]

export default {
    mode: 'history',
    routes: routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
}