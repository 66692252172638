<template>
  <div id="results" class="grid gap-10 xs-1 sm-2 md-3" v-if="data">
    <template v-if="data.payload.length>0">
      <router-link v-for="(result,index) in data.payload" :key="index" :to="getRouteName(index)">
        <div class="is-cover three-two">
          <image-helper :data="data.payload[index].heroImage"></image-helper>
        </div>
        <div class="inner grid gap-5">
          <img v-if="isXs" src="/assets/img/arrow.svg">
          <p v-if="studentProfile(index)" class="title strong">{{result.personLink[0].firstName}} {{result.personLink[0].lastName}}</p>
          <p v-else class="title strong">{{result.title}}</p>
          <p v-if="result.intro" class="intro">{{result.intro}}</p>
          <p v-if="result.byline" class="byline small">{{result.byline}}
            <template v-if="result.byline2"><br/>{{result.byline2}}</template>
          </p>
        </div>
      </router-link>
    </template>

    <template v-if="data && !data.payload.length && !data.total">
      <div class="no-results">
        <p class="strong big d-blue">Sorry.</p>
        <p>We didn't find any results.</p>
      </div>
    </template>

    </div>
  </div>
</template>


<script>

  export default {
    props: {
      data: {
        type: Object,
        required: true
      },
      loaded: Boolean
    },
    data(){
      return {
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
    },
    methods:{
      getRouteName(index){
        switch(this.data.payload[index].sectionHandle){
          case 'studentProfilesChildren':
            return {
              name: 'postgraduateStudy.studentProfiles.studentProfilesChildren',
              params: {
                  slug: this.data.payload[index].slug
                }
              }
          break;
          case 'caseStudies':
            return {
              name: 'ourResearch.ourResearchCaseStudies.caseStudiesChildren',
              params: {
                  slug: this.data.payload[index].slug
                }
              }
          break;
          case 'events':
            return {
              name: 'eventsIndex.events',
              params: {
                  slug: this.data.payload[index].slug
                }
              }
          break;
          case 'updates':
            return {
              name: 'updatesIndex.updates',
              params: {
                  slug: this.data.payload[index].slug
                }
              }
          break;
        }
      },
      studentProfile(index){
        if (this.data.payload[index].sectionHandle == 'studentProfilesChildren')
          return true
        else
          return false
      }
    },
    created(){
      if (this.$store.state.graphQLDebug){
        //console.log('results load')
        //console.log(this.data)
      }
    }
  }
</script>