<template>
  <content-wrapper id="student-profile" class="reverse small-gap" v-if="data.result">
    
    <div class="grid gap-50">

      <div class="hero-nameplate-wrapper">
        <div class="image-wrapper">
          <div class="is-cover three-two">
            <image-helper :data="data.entry.profileImage"></image-helper>
          </div>
        </div>

        <template v-if="isXs">
          <div class="nameplate">
            <p class="v-small v-strong">Student Profile</p>
            <h3 class="heading">{{getStudentName()}}</h3>
          </div>
        </template>
      </div>

     <div class="details grid gap-20">
        <div v-if="student.departments" class="department">
          <p class="strong">Department<template v-if="student.departments.length > 1">s</tempate></p>
          <p v-for="department in student.departments">
            <router-link :to="$buildInternalLink(department)">{{department.title}}</router-link>
          </p>
        </div>
        <div v-if="student.researchAreas" class="research-area">
          <p class="strong">Research area</p>
          <p v-for="researchArea in student.researchAreas">{{researchArea.title}}</p>
        </div>
        <div v-if="student.orcidNumber" class="orcid">
          <p class="strong">ORCID</p>
          <p><a :href="'https://orcid.org/' + student.orcidNumber">{{student.orcidNumber}}</a></p>
        </div>
        <div v-if="student.email || student.phone" class="contact">
          <p class="strong">Contact</p>
          <p><a :href="'mailto:' + student.email">{{student.email}}</a></p>
          <p><a :href="'tel:' + student.phone">{{student.phone}}</a></p>
        </div>
      </div>
      <div v-if="student.researchOverview" class="research-overview">
        <h3>Research overview</h3>
        <div class="wysiwyg" v-html="student.researchOverview"></div>
      </div>
    </div>

    <!-- start dynamic component ordering -->
    <template v-for="component in orderableComponents">
      <component v-for="componentItem in component" :is="$toKebab(componentItem.__typename)" :data="componentItem"></component>
    </template>

    <template v-slot:secondary>
      <div v-if="!isXs" class="nameplate">
        <p class="v-small v-strong">Student Profile</p>
        <h3 class="heading">{{getStudentName()}}</h3>
      </div>
    </template>

  </content-wrapper>
</template>




<script>
  import ContentWrapper from '~/components/ContentWrapper.vue'
  import InlineImages from '~/components/InlineImages.vue'
  import Wysiwyg from '~/components/Wysiwyg.vue'
  import gql from 'graphql-tag'
  import { FRAGMENT_PROFILE_FIELDS } from '~/gqlFragments.js';

  export default {
    components: { ContentWrapper, InlineImages, Wysiwyg },
    data(){
      return {
        data: {
          result: false,
          entry: Object,
        }
      }
    },
    apollo: {
      studentprofile: {
        query: gql`
            query variables($slug: [String!], $section: [String!]){
              studentprofile: entry(slug: $slug, section: $section) {
                id,
                title,
                ...on studentProfilesChildren_studentProfiles_Entry{
                  personLink{
                    ... on people_people_Entry{
                      jobTitle,
                      firstName,
                      lastName,
                      qualification
                    }
                  },
                  profileImage{
                    url,
                    width,title,
                    height,
                    focalPoint,
                    mimeType,
                    s400: url @transform(width: 400),
                    s800: url @transform(width: 800),
                    s1200: url @transform(width: 1200),
                    s1600: url @transform(width: 1600),
                    s2000: url @transform(width: 2000),
                    s2400: url @transform(width: 2400),
                    s2800: url @transform(width: 2800),
                    s3200: url @transform(width: 3200)
                  },
                  departments{
                    id, title, slug, sectionHandle
                  },
                  orcidNumber,
                  email,
                  phone,
                  researchArea
                  researchOverview,
                  contentModules{
                    __typename
                    ...wysiwygModuleProfile
                    ...inlineImagesModuleProfile
                  }
                }
              }
            }
            ${FRAGMENT_PROFILE_FIELDS}
          `,
        variables() {
          if (typeof(this.$route.params.slug) !== 'undefined'){
            return {
              slug: this.$route.params.slug,
              section: 'studentProfilesChildren'
            }
          }
          else{   
            let urlParts = this.$route.path.split('/')
            var lastPart = urlParts.pop();
            return {
              slug: lastPart,
              section: 'studentProfilesChildren'
            }
          }
        },
        result ({ data, loading, networkStatus }) {
          console.log(networkStatus)
          if (data.studentprofile !== null){ 
            this.$store.commit('setTitle',data.studentprofile.personLink[0].firstName + ' ' + data.studentprofile.personLink[0].lastName)
            this.data.entry = data.studentprofile
            this.data.student = data.studentprofile // must be duplicate - will revisit
            this.$store.commit('setLoaded',true)
            this.data.result = true
            if (this.$store.state.graphQLDebug){
              console.log('Full graphQL entry data:')
              console.log(data.studentprofile)
            }
          }
          else{
            this.$router.push({ name: 'errorContent' })
          }
        }
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      student() {
        return this.data.entry
      },
      orderableComponents() {
        // Proof of concept
        // -
        // create an ordered array here from the data and thats it
        // data structure will likely need adjusting to allow for multiples
        // and that will flow onto children component data.
        // a prop for index might be helpful, happy to look into this when we get here

        let contentModuleSet = [];
        if (typeof(this.data.entry.contentModules) !== 'undefined'){
          this.data.entry.contentModules.forEach(contentModule=>{
            
            // contentModules come through as contentModules_LinkGroup_BlockType via graphQL, transpose back to matching Vue component
            let moduleIdentifierParts = contentModule.__typename.split('_')
            let actualModule = moduleIdentifierParts[1]
            let contentModuleItem = [];

            // create array and push into Content Module set
            contentModuleItem[actualModule] = contentModule
            contentModuleSet.push(contentModuleItem)
            contentModule.__typename = actualModule;

          })

          return [this.data.entry.contentModules];
        }
        else
          return [];
      },
    },
    methods: {
      getStudentImage(){
        return '/'+this.data.entry.profileImage[0].url
      },
      getStudentName(){
        let profileSet = []
        profileSet.push(this.data.entry.personLink[0])
        let profileObject = this.$formatAuthors(profileSet)
        return profileSet[0].displayName
      }
    }
  }
</script>