<template>
  <div class="content-wrapper">
    <slot name="header"></slot>
    <div class="container cw-inner" :class="{'has-secondary' : hasSecondary}">
      <div class="cw-primary">
        <slot></slot>
      </div>
      <div v-if="hasSecondary" class="cw-secondary">
        <slot name="secondary"></slot>
      </div>
    </div>
    <div v-if="hasFooter" class="cw-footer container">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>

  export default {
    components: { },
    data(){
      return {
      }
    },
    computed: {
      hasSecondary() {
        return !!this.$slots.secondary
      },
      hasFooter() {
        return !!this.$slots.footer
      },
    },
    created() {
    }
  }
</script>