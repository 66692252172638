<template>
  <div id="publications-results">
    <a v-for="result in results" :href="result.link" target="_blank">
      <img v-if="isXs" src="/assets/img/arrow.svg">
      <p v-if="result.title" class="title">{{result.title}}</p>
      <p v-if="result.authors" class="v-small">{{$formatAuthors(result.authors, 'formal')}}</p>
      <p v-if="result.type || result.year" class="v-small">
        <template v-if="result.type">{{result.type}}<template v-if="result.year">, </template></template>
        <template v-if="result.year">{{result.year}}</template>
      </p>
    </a>
  </div>
</template>


<script>

  export default {
    data(){
      return {
       
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      results() {
        return this.$store.state.results.payload
      },
    },
    methods: {
    }
  }
</script>