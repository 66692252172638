<template>
  <div id="search-overlay" v-scroll-lock="isXs">
    <div class="container">
      <div class="input-wrapper">
        <input v-model="search" type="text" placeholder="Type to search..." ref="search" @keydown.enter="submit">
        <inline-svg :src="$getImageSrc('search.svg')" @click="submit"></inline-svg>
      </div>
    </div>
  </div> 
</template>


<script>

  export default {
    data(){
      return {
        search: null,
        scrollTrigger: null
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
    },
    methods: {
      checkScroll() {
        if(!this.isXs) {
          let offset = 200
          let scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop
          if(scrollPos > this.scrollTrigger + offset || scrollPos < this.scrollTrigger - offset) {
            this.dismiss(true)
          }
        }
      },
      dismiss(event) {
        if (event.keyCode === 27 || event === true) { // ESC key was pressed...
          this.$store.commit('setSearch',false)
        }
      },
      submit() {
        if(this.search) {
          this.dismiss(true)
          this.$setActiveFilters({globalSearch: this.search})
          if(this.$route.name !== 'searchIndex') {
            this.$router.push({name:'searchIndex'})
          }
        }
      }
    },
    created() {
      this.scrollTrigger = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop
      window.addEventListener('keyup', this.dismiss);
      window.addEventListener('scroll', this.checkScroll)
      this.$nextTick(() => this.$refs.search.focus())
    },
    destroyed () {
      window.removeEventListener('keyup', this.dismiss);
      window.removeEventListener('scroll', this.checkScroll);
    },
  }
</script>