<template>
  <div v-if="data" class="wysiwyg" v-html="data.wysiwygContent"></div>
</template>


<script>

  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
      }
    },
  }
</script>