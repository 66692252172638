<template>

  <content-wrapper id="L2" :class="data.slug" v-if="data.result">
    <template v-slot:header>
      <introduction :data="data.entry"></introduction>
      <hero-image :data="data.entry.heroImage"></hero-image>
    </template>

    <!-- start dynamic component ordering -->
    <template v-for="component in orderableComponents">
      <component v-for="componentItem in component" :is="$toKebab(componentItem.__typename)" :data="componentItem"></component>
    </template>

    {{data.usefulLinks}}
    <template v-slot:secondary>
      <useful-links v-if="data.entry.usefulLinks" :data="data.entry.usefulLinks"></useful-links>
    </template>

    <template v-slot:footer>
      <downloads v-if="data.entry.downloads" :data="data.entry.downloads"></downloads>
      <related-content v-if="checkRelatedContent" :data="data.entry"></related-content>
      <contact-form v-if="checkForm" :data="data.entry.contactForm[0]"></contact-form>
    </template>

  </content-wrapper>
</template>

<script>
  import ContentWrapper from '~/components/ContentWrapper.vue'
  import Introduction from '~/components/Introduction.vue'
  import HeroImage from '~/components/HeroImage.vue'
  import Accordion from '~/components/Accordion.vue'
  import InlineImages from '~/components/InlineImages.vue'
  import StaffList from '~/components/L2/StaffList.vue'
  import UsefulLinks from '~/components/UsefulLinks.vue'
  import RelatedContent from '~/components/RelatedContent.vue'
  import Downloads from '~/components/Downloads.vue'
  import ContactForm from '~/components/ContactForm.vue'
  import Wysiwyg from '~/components/Wysiwyg.vue'
  import InlineLogos from '~/components/InlineLogos.vue'
  import gql from 'graphql-tag'
  import { FRAGMENT_L2_FIELDS } from '~/gqlFragments.js';

  export default {
    components: { ContentWrapper, Introduction, HeroImage, Accordion, InlineImages, StaffList, Wysiwyg, InlineLogos, UsefulLinks, RelatedContent, Downloads, ContactForm },
    data(){
      return {
        data: {
          pageTitle: '',
          result: false,
          pageResult: false,
          pageTitle : '',
          entry: Object,
          slug: this.$route.name,
        }
      }
    },
    apollo: {
      entry: {
        query: gql`
            query variables($slug: [String!]){
              entry(slug: $slug) {
                id,
                title,
                ... on ourResearchChildren_ourResearch_Entry{
                  parent{title,sectionHandle,slug},
                  heroImage { url,
                  width,
                  height,
                  focalPoint,
                  mimeType,
                  s400: url @transform(width: 400),
                  s800: url @transform(width: 800),
                  s1200: url @transform(width: 1200),
                  s1600: url @transform(width: 1600),
                  s2000: url @transform(width: 2000),
                  s2400: url @transform(width: 2400),
                  s2800: url @transform(width: 2800),
                  s3200: url @transform(width: 3200) },
                   introductionModuleL2{
                        ...introModuleL2
                      }
                  contentModules{
                    __typename
                    ...linkGroupModuleL2
                    ...wysiwygModuleL2
                    ...accordionModuleL2
                    ...staffModuleL2
                    ...inlineImagesModuleL2
                  },
                  usefulLinks{
                    ...usefulLinksL2
                  },
                  downloads{
                    ...downloadsL2
                  },
                  relatedLinks{
                    ...relatedLinksL2
                  },
                  contactForm{
                    ...contactFormL2
                  },
                  showRelatedEvents,
                  showRelatedUpdates
                },
                ... on industryChildren_industryChildren_Entry{
                  parent{title,sectionHandle,slug},
                  heroImage { url,
                  width,
                  height,
                  focalPoint,
                  mimeType,
                  s400: url @transform(width: 400),
                  s800: url @transform(width: 800),
                  s1200: url @transform(width: 1200),
                  s1600: url @transform(width: 1600),
                  s2000: url @transform(width: 2000),
                  s2400: url @transform(width: 2400),
                  s2800: url @transform(width: 2800),
                  s3200: url @transform(width: 3200) },
                   introductionModuleL2{
                        ...introModuleL2
                      }
                  contentModules{
                    __typename
                    ...linkGroupModuleL2
                    ...wysiwygModuleL2
                    ...accordionModuleL2
                    ...staffModuleL2
                  },
                  usefulLinks{
                    ...usefulLinksL2
                  },
                  downloads{
                    ...downloadsL2
                  },
                  relatedLinks{
                    ...relatedLinksL2
                  },
                  contactForm{
                    ...contactFormL2
                  },
                  showRelatedEvents,
                  showRelatedUpdates
                },
                ... on postgraduateStudyChildren_postgraduateStudyChildren_Entry{
                  parent{title,sectionHandle,slug},
                  heroImage { url,
                  width,
                  height,
                  focalPoint,
                  mimeType,
                  s400: url @transform(width: 400),
                  s800: url @transform(width: 800),
                  s1200: url @transform(width: 1200),
                  s1600: url @transform(width: 1600),
                  s2000: url @transform(width: 2000),
                  s2400: url @transform(width: 2400),
                  s2800: url @transform(width: 2800),
                  s3200: url @transform(width: 3200) },
                   introductionModuleL2{
                        ...introModuleL2
                      }
                  contentModules{
                    __typename
                    ...linkGroupModuleL2
                    ...wysiwygModuleL2
                    ...accordionModuleL2
                    ...staffModuleL2
                    ...inlineImagesModuleL2
                  },
                  usefulLinks{
                    ...usefulLinksL2
                  },
                  downloads{
                    ...downloadsL2
                  },
                  relatedLinks{
                    ...relatedLinksL2
                  },
                  contactForm{
                    ...contactFormL2
                  },
                  showRelatedEvents,
                  showRelatedUpdates
                },
                ... on testingAnalyticalServicesChildren_testingChildren_Entry{
                  parent{title,sectionHandle,slug},
                  heroImage { url,
                  width,
                  height,
                  focalPoint,
                  mimeType,
                  s400: url @transform(width: 400),
                  s800: url @transform(width: 800),
                  s1200: url @transform(width: 1200),
                  s1600: url @transform(width: 1600),
                  s2000: url @transform(width: 2000),
                  s2400: url @transform(width: 2400),
                  s2800: url @transform(width: 2800),
                  s3200: url @transform(width: 3200) },
                   introductionModuleL2{
                        ...introModuleL2
                      }
                  contentModules{
                    __typename
                    ...linkGroupModuleL2
                    ...wysiwygModuleL2
                    ...accordionModuleL2
                    ...staffModuleL2
                  },
                  usefulLinks{
                    ...usefulLinksL2
                  },
                  downloads{
                    ...downloadsL2
                  },
                  relatedLinks{
                    ...relatedLinksL2
                  },
                  contactForm{
                    ...contactFormL2
                  },
                  showRelatedEvents,
                  showRelatedUpdates
                },
                ... on faculties_faculties_Entry{
                  parent{title,sectionHandle,slug},
                  heroImage { url,
                  width,
                  height,
                  focalPoint,
                  mimeType,
                  s400: url @transform(width: 400),
                  s800: url @transform(width: 800),
                  s1200: url @transform(width: 1200),
                  s1600: url @transform(width: 1600),
                  s2000: url @transform(width: 2000),
                  s2400: url @transform(width: 2400),
                  s2800: url @transform(width: 2800),
                  s3200: url @transform(width: 3200) },
                   introductionModuleL2{
                        ...introModuleL2
                      }
                  contentModules{
                    __typename
                    ...linkGroupModuleL2
                    ...wysiwygModuleL2
                    ...accordionModuleL2
                    ...staffModuleL2
                    ...inlineImagesModuleL2
                  },
                  usefulLinks{
                    ...usefulLinksL2
                  },
                  downloads{
                    ...downloadsL2
                  },
                  relatedLinks{
                    ...relatedLinksL2
                  },
                  contactForm{
                    ...contactFormL2
                  },
                  showRelatedEvents,
                  showRelatedUpdates
                },
              }
            }
            ${FRAGMENT_L2_FIELDS}
          `,
          variables() {
            if (typeof(this.$route.params.slug) !== 'undefined'){
              if (typeof(this.$route.params.L3slug) !== 'undefined'){
                return {
                  slug: this.$route.params.L3slug
                }
              }
              else{
                 return {
                  slug: this.$route.params.slug
                }
              }
            }
            else{   
              let urlParts = this.$route.path.split('/')
              var lastPart = urlParts.pop();
              return {
                slug: lastPart
              }
            }
          },
        result ({ data, loading, networkStatus }) {
          if (data.entry !== null){ 
            this.data.pageTitle = data.entry.title
            this.$store.commit('setPageID',data.entry.id)
            this.$store.commit('setTitle',data.entry.title)
            if (data.entry.parent !== null){
              this.$store.commit('setParentTitle',data.entry.parent.title)
              this.$store.commit('setParentSectionHandle',data.entry.parent.sectionHandle)
              this.$store.commit('setParentSlug',data.entry.parent.slug)
            }
            this.$store.commit('setTitle',data.entry.title)
            this.data.entry = data.entry
            this.$store.commit('setLoaded',true)
            this.data.result = true
            if (this.$store.state.graphQLDebug){
              console.log('Full graphQL entry data:')
              console.log(this.data.entry)
            }
          }
            else{
              this.$router.push({ name: 'errorContent' })
            }
          }
        }
    },
    computed: {
      orderableComponents() {
        // slighty more refined - have an approach now thats tidy and consistent
        let contentModuleSet = [];
        if (typeof(this.data.entry.contentModules) !== 'undefined'){
          this.data.entry.contentModules.forEach(contentModule=>{
            //console.log(contentModule.__typename)
            if (typeof(contentModule.wysiwygContent) !== 'undefined'){
              let actualModule = 'Wysiwyg'
              contentModule.__typename = actualModule
            }
            if (typeof(contentModule.linkSet) !== 'undefined'){
              let actualModule = 'LinkGroup'
              contentModule.__typename = actualModule
            }
            if (typeof(contentModule.groupTitle) !== 'undefined'){
              let actualModule = 'Accordion'
              contentModule.__typename = actualModule
            }
            if (typeof(contentModule.images) !== 'undefined'){
              let actualModule = 'InlineImages'
              contentModule.__typename = actualModule
            }
            if (typeof(contentModule.staffIntroduction) !== 'undefined'){
              let actualModule = 'StaffList'
              contentModule.__typename = actualModule
            }
            if (typeof(contentModule.quote) !== 'undefined'){
              let actualModule = 'Quote'
              contentModule.__typename = actualModule
            }
            if (typeof(contentModule.students) !== 'undefined'){
              let actualModule = 'FeaturedStudents'
              contentModule.__typename = actualModule
            }

          })
          return [this.data.entry.contentModules];
        }
        else
          return [];
      },
      checkLoaded(){
        return this.data.result
      },
      checkRelatedContent(){
        if (this.data.entry.relatedLinks.length || this.data.entry.showRelatedEvents || this.data.entry.showRelatedUpdates)
          return true
        else
          return false
      },
      checkForm(){
        return this.data.entry.contactForm.length
      }
    },
    created() {
      this.$store.commit('setTitle','')
      this.$store.commit('setParentTitle','')
      this.$store.commit('setParentSectionHandle','')
      this.$store.commit('setParentSlug','')
    }
  }
</script>