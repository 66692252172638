<template>
  <figure v-if="data" class="inline-images">
    <div class="img-wrapper">
      <template v-if="imgCount">
        <inline-svg :src="$getImageSrc('arrow.svg')" class="prev" @click="prev()"></inline-svg>
        <inline-svg :src="$getImageSrc('arrow.svg')" class="next" @click="next()"></inline-svg>
      </template>
      <image-helper :data="getImage(activeItem)"></image-helper>
    </div>
    <figcaption class="p-small">
      <span v-if="imgCount">{{activeItem + 1}}/{{imgCount + 1}}</span>
      {{data.images[activeItem].inlineImageCaption}}
    </figcaption>
  </figure>
</template>


<script>

  export default {
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    data(){
      return {
        activeItem: 0
      }
    },
    computed: {
      imgCount() {
        return this.data.images.length -1
      },
    },
    methods: {
      next() {
        if(this.activeItem !== this.imgCount){
          this.activeItem++
        } else {
          this.activeItem = 0
        }
      },
      prev() {
        if(this.activeItem === 0){
          this.activeItem = this.imgCount
        } else {
          this.activeItem--
        }
      },
      getImage(activeItem){
        return this.data.images[activeItem].inlineImage
      }
    }
  }
</script>