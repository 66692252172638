<template>
  <content-wrapper id="L1" :class="data.slug" class="slim" v-if="data.result">
        
    <template v-slot:header>
      <hero-image :data="data.entry.heroModule"></hero-image>
    </template>

    <introduction :data="data.entry.introductionModule"></introduction>

    <template>
      <template v-for="component in orderableComponents">
      <component v-for="componentItem in component" :is="$toKebab(componentItem.__typename)" :data="componentItem"></component>
      </template>
    </template>

  </content-wrapper>
</template>

<script>
  import ContentWrapper from '~/components/ContentWrapper.vue'
  import HeroImage from '~/components/L1/HeroImage.vue'
  import Introduction from '~/components/L1/Introduction.vue'
  import LinkGroup from '~/components/L1/LinkGroup.vue'
  import Accordion from '~/components/Accordion.vue'
  import FeaturedStudents from '~/components/L1/FeaturedStudents.vue'
  import Wysiwyg from '~/components/Wysiwyg.vue'
  import gql from 'graphql-tag'
  import { FRAGMENT_L1_L2_FIELDS } from '~/gqlFragments.js';

  export default {
    components: { ContentWrapper, HeroImage, Introduction, LinkGroup, Accordion, FeaturedStudents, Wysiwyg },
    data(){
      return {
        data: {
          result: false,
          pageTitle : '',
          entry: Object,
          slug: this.$route.name,
        }
      }
    },
    apollo: {
      entry: {
        query: gql`
            query variables($section: [String!]){
              entry(section: $section) {
                id,
                title,
                ... on ourResearchIndex_ourResearchIndex_Entry{
                    introductionModule{
                        ...introModuleL1L2
                      }
                      heroModule{
                        ...heroImgModuleL1L2
                      },
                    contentModules{
                    __typename
                      ...linkGroupModuleL1L2
                      ...wysiwygModuleL1L2
                      ...accordionModuleL1L2
                      ...featuredStudentsModuleL1L2
                  }
                },
                ... on industryPartnershipsIndex_industryPartnershipsIndex_Entry{
                    introductionModule{
                        ...introModuleL1L2
                      }
                      heroModule{
                        ...heroImgModuleL1L2
                      },
                    contentModules{
                    __typename
                      ...linkGroupModuleL1L2
                      ...wysiwygModuleL1L2
                      ...accordionModuleL1L2
                      ...featuredStudentsModuleL1L2
                  }
                },
                ... on testingAnalyticalServicesIndex_testingAnalyticalServicesIndex_Entry{
                    introductionModule{
                        ...introModuleL1L2
                      }
                      heroModule{
                        ...heroImgModuleL1L2
                      },
                    contentModules{
                    __typename
                      ...linkGroupModuleL1L2
                      ...wysiwygModuleL1L2
                      ...accordionModuleL1L2
                      ...featuredStudentsModuleL1L2
                  }
                },
                ... on postgraduateStudyIndex_postgraduateStudyIndex_Entry{
                    introductionModule{
                        ...introModuleL1L2
                      }
                      heroModule{
                        ...heroImgModuleL1L2
                      },
                    contentModules{
                    __typename
                      ...linkGroupModuleL1L2
                      ...wysiwygModuleL1L2
                      ...accordionModuleL1L2
                      ...featuredStudentsModuleL1L2
                  }
                }
              }
            }
            ${FRAGMENT_L1_L2_FIELDS}
          `,
          variables() {
            if (this.$route.name == 'industry'){
              return{
                section: 'industryPartnershipsIndex'
              }
            }
            else{
              return {
                section: this.$route.name+'Index'
              }
            }
          },
          result ({ data, loading, networkStatus }) {
            if (data.entry !== null){ 
              this.$store.commit('setTitle',data.entry.title)
              this.data.entry = data.entry
              this.$store.commit('setLoaded',true)
              this.data.result = true
              if (this.$store.state.graphQLDebug){
                console.log('Full graphQL entry data:')
                console.log(data.entry)
              }
            }
            else{
              this.$router.push({ name: 'errorContent' })
            }
          }
        }
    },
    computed: {
      orderableComponents() {
        // slighty more refined - have an approach now thats tidy and consistent
        let contentModuleSet = [];
        if (typeof(this.data.entry.contentModules) !== 'undefined'){
          this.data.entry.contentModules.forEach(contentModule=>{
            // create array and push into Content Module set
            if (typeof(contentModule.wysiwygContent) !== 'undefined'){
              let actualModule = 'Wysiwyg'
              contentModule.__typename = actualModule
            }
            if (typeof(contentModule.linkSet) !== 'undefined'){
              let actualModule = 'LinkGroup'
              contentModule.__typename = actualModule
            }
            if (typeof(contentModule.groupTitle) !== 'undefined'){
              let actualModule = 'Accordion'
              contentModule.__typename = actualModule
            }
            if (typeof(contentModule.images) !== 'undefined'){
              let actualModule = 'InlineImages'
              contentModule.__typename = actualModule
            }
            if (typeof(contentModule.staffIntroduction) !== 'undefined'){
              let actualModule = 'StaffList'
              contentModule.__typename = actualModule
            }
            if (typeof(contentModule.quote) !== 'undefined'){
              let actualModule = 'Quote'
              contentModule.__typename = actualModule
            }
            if (typeof(contentModule.students) !== 'undefined'){
              let actualModule = 'FeaturedStudents'
              contentModule.__typename = actualModule
            }
            
          })
          return [this.data.entry.contentModules];
        }
        else
          return [];
      },
      checkLoaded(){
        return this.data.result
      }
    }
  }
</script>