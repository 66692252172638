<template>
  <div class="quote">
    <blockquote>
      {{data.quote}}
      <cite>{{data.cite}}</cite>
    </blockquote>
  </div>
  
</template>


<script>

  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
      }
    },
  }
</script>