<template>
  <div v-if="data.length" class="hero-image is-cover">
    <image-helper :data="data[0].heroImage"></image-helper>
    <h1 class="container">{{data[0].heroTitle}}</h1>
  </div>
</template>


<script>

  export default {
    props: {
      data: {
        type: Array,
        required: true
      }
    },
  }
</script>