<template>
  <div v-if="data" class="link-group grid">
    <!--<div class="item" v-for="(item,index) in data.linkSet" @click="$router.push(item.linkSetURL)"> orig when simple url string -->
    <router-link v-for="(item,index) in data.linkSet" :to="$buildInternalLink(item.linkSetURL)" class="item">
      <img v-if="!isXs" src="/assets/img/arrow.svg" class="arrow">
      <div class="outer grid no-gap">
        <div class="is-cover" :class="{'four-three' : isXs}">
          <image-helper :data="item.linkSetImage"></image-helper>
        </div>
        <div class="inner">
          <h3 class="heading">{{item.linkSetTitle}}</h3>
          <p v-if="item.linkSetByline" class="small">{{item.linkSetByline}}</p>
          <img v-if="isXs" src="/assets/img/arrow.svg">
        </div>
      </div>
    </router-link>
    <!--</div>-->
  </div>
</template>


<script>

  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      }
    },
    methods: {
      goto(link) {
        this.$router
      }
    }
  }
</script>