<template>
  <div v-if="data.length" id="useful-links">
    <h4>Useful links</h4>
    <template v-for="link,index in data">
      <a v-if="link.linkExternal !== ''" :href="link.linkExternal" target="_blank" class="p-small" v-html="title(link.linkTitle)"></a>
      <router-link v-else :to="$buildInternalLink(link.existingPage)" class="p-small" v-html="title(link.linkTitle)"></router-link>
    </template>
  </div>
</template>


<script>
  export default {
    props: {
      data: {
        type: Array,
        required: true,
        
      }
    },
    data(){
      return {
      }
    },
    methods: {
      title(title) {
        title = title.split(" ");
        let last = '<span>' + title[title.length - 1] + '</span>'
        title.pop()
        title.push(last)
        return title.join(' ')
      },
    },
  }
</script>