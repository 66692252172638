<template>
  <div v-if="data" class="staff-list grid gap-20">
    <h3>Lincoln University Programmes</h3>
    <div class="wysiwyg" v-html="data.staffIntroduction"></div>
    <h3>Staff</h3>
    <div class="grid gap-10">
      <div class="staff " v-for="staff,index in data.staff">
        <template v-if="checkStudentProfile(index)" >
          <router-link :to="buildProfileRoute(index)">{{getDisplayName(staff)}}</router-link><br>
        </template>
        <template v-else>
          <template v-if="getLink(index)">
            <a :href="getLink(index)">{{getDisplayName(staff)}}</a><br>
          </template>
          <template v-else>
            {{getDisplayName(staff)}}<br>
          </template>
        </template>
        <template v-if="staff.jobTitle">{{staff.jobTitle}}</template>
      </div>
    </div>
  </div>
</template>


<script>
  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
      }
    },
    methods:{
      getLink(index){
        if (this.data.staff[index].externalLink != '')
          return this.data.staff[index].externalLink
        else
          return false
      },
      checkStudentProfile(index){
        if (this.data.staff[index].studentProfile === true){
          return true
        }
        else
          return false
      },
      getDisplayName(staff){
        let staffSet = []
        staffSet.push(staff)
        let staffObject = this.$formatAuthors(staffSet)
        return staffObject[0].displayName
      },
      buildProfileRoute(index){
        let slug = this.$toKebab(this.data.staff[index].firstName + ' ' + this.data.staff[index].lastName)
        return {
          name: 'postgraduateStudy.studentProfiles.studentProfilesChildren',
          params: {
            slug: slug
          }
        }
      }
    }
  }
</script>