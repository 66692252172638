<template>
  <main>
    <app-menu v-if="!errorPage"></app-menu>
    <div class="content" :class="{'has-breadcrumb': breadcrumb}" :key="this.route">
      <slot></slot>
    </div>
    <app-footer v-if="!errorPage"></app-footer>
  </main>
</template>

<script>
  import AppMenu from '~/components/AppMenu.vue'
  import AppFooter from '~/components/AppFooter.vue'

  export default {
    components: {AppMenu, AppFooter},
    data() {
      return {
        componentKey: 0
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      route() {
        return this.$route.name
      },
      errorPage(){
        if (this.route === 'errorContent' || this.route === 'errorServer')
          return true
        else
          return false
      },
      menuActive() {
        return this.$store.state.menu
      },
      breadcrumb() {
        return this.$store.state.breadcrumb
      }
    },
  }
</script>


<!-- 
NOTES : 
We *may* not need this once we have 'acutal data'
currently this is helping with a scrolling bug that is 
triggered when changing between two pages using the Search layout
and ensuring that we get (or dont get) the right introduction content
=> https://michaelnthiessen.com/force-re-render/


*R* temporarily disabled to enable FadeTransition etc
-->