<template>
  <default :class="{'loading' : loaded}">
    <router-view :key="$route.fullPath"></router-view>
  </default>
</template>

<script>
  import Default from '~/components/layouts/Default.vue'

  import gql from 'graphql-tag'
  const container = {
    xs: window.innerWidth,
    sm: 536,
    md: 728,
    lg: 952,
    xl: 1160
  }

  export default {
    components: { Default },
    data(){
      return {
        breakpoint: '',
      }
    },
    computed: {
      loaded() {
        return !this.$store.state.loaded
      },
    },
    apollo: {
      entries: {
        query: gql`
          query variables($section: [String!]){
            entries(section: $section,orderBy: "lft"){  
              sectionHandle title slug uri parent {sectionHandle}
            }
          }
        `,
        variables(){
          return {
            section: ["ourResearchChildren","testingAnalyticalServicesChildren","industryChildren","postgraduateStudyChildren","findAResearcherSupervisor"]
          }
        },
        result ({ data, loading, networkStatus }){

          if (data.entries !== null){ 
            if (this.$store.state.graphQLDebug){
              console.log('Full graphQL nav data:')
              console.log(data.entries)
            }
            this.$store.commit('setMenuEntries', data.entries)
            this.$store.commit('setLoaded', true)
          }
          else{
            this.$router.push({ name: 'errorServer' })
          }
          // output full graphQL result for debug
          // console.log('Full graphQL nav data:')
          // console.log(data.entries)
          
        }
      },
    },
  	methods: {
  		setup() {
        this.breakpointCheck();
  			this.cssVars();
  			console.log('%cDeveloped by Kichin.', 'color: #000000; font-weight: bold; font-size: 11px;');
  			console.log('%chttps://kichin.co ', 'color: #000000; font-weight: bold;');
  			console.log('–');

  		},
  		resize(e) {
        container.xs = window.innerWidth;
  			this.breakpointCheck();
  			this.cssVars();
  		},
      breakpointCheck(resize) {
        let w = window.innerWidth;
        let breakpoint = '';

        // check these values against /assets/scss/_variables
        if (w <= 576) {
          breakpoint = 'xs';
        } else if (w > 576 && w <= 767) {
          breakpoint = 'sm';
        } else if (w >= 768 && w <= 991) {
          breakpoint = 'md';
        } else if (w >= 992 && w <= 1199){
          breakpoint = 'lg';
        } else {
          breakpoint = 'xl';
        }

        if (breakpoint !== this.breakpoint) {
          this.breakpoint = breakpoint
          this.$store.commit('setBreakpoint', breakpoint);
          console.log('Breakpoint : ' + breakpoint)
        }
      },
      cssVars() {
        // Handle VH units : get the viewport height and multiply it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');

        let col = container[this.breakpoint] / 8 ;
        document.documentElement.style.setProperty('--col', col + 'px');
      },
    },
    metaInfo() {
      return {
        title: this.$route.meta.title || this.$store.state.title || this.$store.state.defaultTitle,
        // meta: [
        //   { name:'description', content: this.$store.state.description, vmid:'description' },
        //   { property:'og:title', content: this.$route.meta.title || this.$store.state.title || this.$store.state.defaultTitle, vmid:'og:title'},
        //   { property:'og:description', content: this.$store.state.description, vmid:'og:description' },
        //   { property:'og:image', content: window.location.origin + this.$getImageSrc('og-image'), vmid:'og:image' },
        //   { property:'og:url', content: window.location.href, vmid:'og:url' }
        // ]
      }
    },
    created() {
      this.setup();
      window.addEventListener("resize", this.resize);
    },
    watch: {
      $route() {
        this.$store.commit('setTitle',false)
        this.$store.commit('setLoaded',false)
      }
    }
  }
</script>