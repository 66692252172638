<template>
  <div id="researcher-supervisior-results" class="grid gap-10" v-if="data">
    <template v-if="data.payload.length>0">
    <div class="accordion-wrapper">
      <accordion-item v-for="(staff,key) in data.payload">
        <template v-slot:title>
          <div class="title-inner grid">
            <p class="strong l-blue">{{displayName(staff)}}</p>
            <p v-if="staff.jobTitle">{{staff.jobTitle}}</p>
            <p v-if="staff.qualification">{{convertQuals(staff.qualification)}}</p>
            <p v-if="staff.discoveryDepartment">{{staff.discoveryDepartment}}</p>
          </div>
        </template>
        <template v-slot:content>
          <div class="content-inner grid gap-20 ">
            <div class="details">
              <p v-if="staff.email"><strong>Email: </strong><a :href="'mailto:'+ staff.email">{{staff.email}}</a></p>
              <p v-if="staff.phone"><strong>Phone: </strong><a :href="'tel:'+ staff.phone">{{staff.phone}}</a></p>
            </div>
            <a :href="'https://researchers.lincoln.ac.nz/'+staff.discoveryLink" class="profile-link" target="_blank"><img src="/assets/img/arrow-long.svg">View full staff profile</a>
            <img :src="'https://researchers.lincoln.ac.nz/'+staff.discoveryLink+'/photo'" @error="imageLoadError" class="col sm-row-2">
            <!--<image-helper :data="staff.image" class="col sm-row-2">-->
          </div>
        </template>
      </accordion-item>
    </div>
    </template>

    <template v-if="data && !data.payload.length && !data.total">
      <div class="no-results">
        <p class="strong big d-blue">Sorry.</p>
        <p>We didn't find any results.</p>
      </div>
    </template>
  </div>
</template>


<script>
  import AccordionItem from '~/components/AccordionItem.vue'

  export default {
    components: { AccordionItem },
    props: {
      data: {
        type: Object,
        required: true
      },
      loaded: Boolean
    },
    data(){
      return {
      }
    },
    computed: {
    },
    methods: {
      displayName(staff) {
        let result = []
        //result.push(staff.title)
        result.push(staff.firstName)
        result.push(staff.lastName)
        if(staff.qualification) {
          result.push(', ' + this.convertQuals(staff.qualification)) // have to conditional as filter will parse the comma as a value
        }
        result = result.filter(n => n).join(' ')
        result = result.replace(/ ,/g, ',') // join leaves us with the comma in the wrong place
        return result
      },
      convertQuals(qualifications){
        let trimQuals = qualifications.replace('[','')
        trimQuals = trimQuals.replace(']','')
        trimQuals = trimQuals.replaceAll('"','')
        trimQuals = trimQuals.replaceAll(',',', ')
        return trimQuals
      },
      imageLoadError (e) {
        //console.log(e.target.parentNode.children[1].hide)
        e.target.parentNode.children[1].style.display='none';
        e.target.src = this.$getImageSrc('pixel.png')
      }
    }
  }
</script>
