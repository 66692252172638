<template>
  <div id="menu-overlay" v-scroll-lock="true" @click.self="dismiss(true)">
    <div class="container" >
      <ul class="wrapper">
        <li v-for="l1 in menu" class="parent">
          <a v-if="l1.key == 'findAResearcherSupervisor'" href="https://researchers.lincoln.ac.nz">Find a Researcher/Supervisor</a>
          <router-link v-else :to="{name: l1.key}" @click.native="dismiss(true)">{{l1.label}}</router-link>
          <ul v-if="l1.children">
            <li v-for="childEntry,index in entries" v-if="childEntry.sectionHandle == l1.key+'Children'" class="child">
                <router-link v-if="checkL3(childEntry)" :to="buildLink(l1.key,childEntry.sectionHandle,childEntry.slug)" @click.native="dismiss(true)">{{childEntry.title}}</router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div> 
</template>

<script>
  export default {
    data(){
      return {
        menu: [
          {key: 'home', label: 'Home', children: false},
          {key: 'ourResearch', label: 'Our Research', children: true},
          {key: 'testingAnalyticalServices', label: 'Testing & Analytical Services', children: true},
          {key: 'industry', label: 'Industry & Partnerships', children: true},
          {key: 'postgraduateStudy', label: 'PostGraduate Study', children: true},
          {key: 'findAResearcherSupervisor', label: 'Find a Researcher/Supervisor', children: false},
          {key: 'updatesIndex', label: 'Updates', children: false},
          // {key: 'eventsIndex', label: 'Events', children: false},
          {key: 'contactIndex', label: 'Contact', children: false}
        ]
      }
    },
    props: {entries: {} },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },      
    },
    methods: {
      dismiss(event) {
        if (event.keyCode === 27 || event === true) { // ESC key was pressed...
          this.$store.commit('setMenu',false)
        }
      },
      buildLink(l1Key, childSectionHandle, slug){
        // Build router-link because there are some special case switches of L2 -> Search mode that need mapped to router.js
        switch (slug){
          case 'case-studies':
            return {
              name: l1Key + '.ourResearchCaseStudies'
            }
          break;
          case 'student-profiles':
            return {
              name: l1Key + '.studentProfiles'
            }
          break;
          case 'research-data':
            return {
              name: 'ourResearch.ourResearchData'
            }
          break;
          case 'publications':
            return {
              name: 'ourResearch.ourResearchPublications'
            }
          break;
          case 'student-profiles':
            return {
              name: l1Key + '.studentProfiles'
            }
          break;
          case 'findAResearcherSupervisor':
            return {
              name: 'findAResearcherSupervisor'
            }
          break;
          default:
            return{
              name: l1Key + '.' + childSectionHandle,
              params: {
                slug: slug
              }
            }
          break;
        }
      },
      checkL3(childEntry){
        if (childEntry.parent === null)
          return true
        else
          return false
      }
    },
    created() {
      window.addEventListener('keyup', this.dismiss);
    },
    destroyed () {
      window.removeEventListener('keyup', this.dismiss);
    },
  }
</script>

