<template>
  <div v-if="show" id="global-search-results" :class="{'no-rule' : !data.payload.length}">
    <template v-if="data.payload.length>0">
      <a v-for="result,index in data.payload" :href="getReadableLink(index)" :key="index">
        <img src="/assets/img/arrow.svg">
        <p class="strong d-blue">{{getTitle(index)}}</p>
        <p class="small">{{getReadableLink(index)}}</p>
      </a>
    </template>
    <template v-if="data && !data.payload.length && !data.total">
      <div class="no-results">
        <p class="strong big d-blue">Sorry.</p>
        <p>We didn't find any results.</p>
      </div>
    </template>
  </div>
</template>


<script>

  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
      }
    },
    computed: {
      show() {
        return this.$route.name === 'searchIndex'
      },

    },
    methods:{
      getReadableLink(index){
        if (this.data.payload[index].sectionHandle == 'researchers')
          return 'https://researchers.lincoln.ac.nz/'+this.data.payload[index].discoveryLink
        else
          return this.$router.resolve(this.$buildInternalLink(this.data.payload[index])).href
      },
      getTitle(index){
        if (this.data.payload[index].sectionHandle == 'researchers')
          return this.data.payload[index].firstName + ' ' + this.data.payload[index].lastName
        else
          return this.data.payload[index].title
      }
    }
  }
</script>