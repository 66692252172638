<template>
  <div id="article-detail" class="grid">
    
    <h1 :class="{'body' : isCasestudy}" class="small">{{title}}</h1>

    <p v-if="data.articleIntroduction" class="strong d-blue intro">{{data.articleIntroduction}}</p>

    <div class="details grid gap-20">
      <template v-if="isArticle || isCasestudy">
        <p v-if="isArticle">Posted {{data.publishedDate}}</p>
        
        <div v-if="authors.length" class="researchers" >
          <p class="strong">Researchers</p>
          <p v-for="author,index in authors">
            <template v-if="checkStudentProfile(author)" >
             <router-link :to="studentProfileRoute(author)">{{author.displayName}}</router-link>
            </template>
            <template v-else>
              <template v-if="getLink(author)">
                <a :href="getLink(author)">{{author.displayName}}</a>
              </template>
              <template v-else>
                {{author.displayName}}
              </template>
            </template>
          </p>
        </div>

        <div v-if="data.departments.length" class="department">
          <p class="strong">Department</p>
          <p v-for="department in data.departments">
            <router-link :to="{name: 'ourResearch.facultiesResearchCentres.L3', params:{slug: department.link}}">{{department.name}}</router-link>
          </p>
        </div>
        <div v-if="data.researchAreas.length" class="research-area">
          <p class="strong">Research areas</p>
          <p v-for="researchArea in data.researchAreas">{{researchArea.title}}</p>
        </div>
      </template>

      <template v-else>
        <div class="event-detail">
          <p v-if="data.eventDate">{{data.eventDate}}</p>
          <p v-if="data.eventTime">{{data.eventTime}}</p>
          <p v-if="data.eventDate" v-for="location in data.eventLocation">
            <template v-if="location.locationLink" >
              <a :href="location.locationLink" target="_blank">
                <template v-if="location.locationName">{{location.locationName}}</template>
                <template v-else>{{$displayUrl(location.locationLink)}}</template>
              </a>
            </template>
            <template v-else>
              {{location.name}}
            </template>
          </p>
        </div>
      </template>
    </div>

    <image-helper :data="this.data.heroImage"></image-helper>
  </div>
</template>


<script>

  export default {
    props: {
      data: {
        type: Object,
        required: true, 
      }
    },
    data(){
      return {
      }
    },
    computed: {
      isArticle() {
        return this.$route.name.includes('updates')
      },
      isEvent() {
        return this.$route.name.includes('events')
      },
      isCasestudy() {
        switch(this.data.__typename){
          case "caseStudies_caseStudies_Entry":
            return true
          break;
          default:
            return false;
          break;
        }
      },
      authors() {
        return this.$formatAuthors(this.data.authors,'display')
      },
      title() {
        return this.data.fullTitle || this.data.title
      }
    },
    methods:{
      getLink(author){
        if (author.externalLink != '')
          return author.externalLink
        else
          return false
      },
      checkStudentProfile(author){
        if (author.studentProfile == true)
          return true
        else
          return false
      },
      studentProfileRoute(author){
        let slug = this.$slugify(author.firstName + ' ' + author.lastName)
        return {
          name: 'postgraduateStudy.studentProfiles.studentProfilesChildren',
          params: {
            slug: slug
          }
        }
      }
    }
  }
</script>