<template>
  <div id="filter-bar" :class="colCount">
    <div v-if="!isGlobal" v-for="(filter, key) in data.filters" class="select-wrapper">
      
      <!-- Start optional dynamic width option – amend in CSS also -->
      <!-- <template v-if="!isXs">
        <span v-if="filters[key] === ''">Filter by {{capitalise(key)}}</span>
        <template v-else>
          <span v-for="filterOption in filter" v-if="$toKebab(filterOption) === filters[key]">{{filterOption}}</span>
        </template>
      </template> -->
      <template v-if="!isResearchers">
        <select @change="submitFilter(key,$event)">
          <option value="" disabled :selected="filters[key] === ''">Filter by {{capitalise(key)}}</option>
          <option value="" disabled>–</option>
          <option v-for="filterOption in filter" :value="filterOption.id" :selected="filterOption.id === filters[key]">{{filterOption.title}}</option>
        </select>
      </template>

      <template v-if="isResearchers">
        <select @change="submitFilter(key,$event)">
          <option value="" disabled :selected="filters[key] === ''">Filter by {{capitalise(key)}}</option>
          <option value="" disabled>–</option>
          <option v-for="filterOption in filter" :value="filterOption.title" :selected="filterOption.title === filters[key]">{{filterOption.title}}</option>
        </select>
      </template>
    </div>
    <div v-if="data.search" class="search-wrapper">
      <inline-svg :src="$getImageSrc('search.svg')" @click="submitSearch()"></inline-svg>
      <input type="text" name="search" placeholder="Search" ref="searchInput" @keydown.enter="submitSearch($event)" :value="filters.search">
    </div>
  </div>
</template>


<script>

  export default {
    data(){
      return {
      }
    },
    computed: {
      data() {
        return this.$store.state.craftPayload.searchSettings
      },
      filters() {
        return this.$store.state.activeFilters
      },
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      colCount() {
        if(this.isGlobal) {
          return 1
        } else {
          return (this.isXs) ? false : 'sm-' + (Object.keys(this.data.filters).length + 1)
        }
      },
      isGlobal() {
        return this.$route.name === 'searchIndex'
      },
      isResearchers() {
        return this.$route.name === 'findAResearcherSupervisor'
      },
    },
    methods: {
      capitalise(key){
        return key.charAt(0).toUpperCase() + key.substr(1).toLowerCase()
      },
      submitSearch(event){
        let value = (event) ? event.target.value : this.$refs.searchInput.value
        this.$setActiveFilters({search: value})
      },
      submitFilter(key,event){
        console.log('this filter triggered: ' + key + ':' + event.target.value)
        this.$setActiveFilters({[key]: event.target.value})
      }
    }
  }
</script>