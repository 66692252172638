<template>
  <div v-if="data" class="accordion grid gap-20">
    <h3 v-if="data.groupTitle" class="title">{{data.groupTitle}}</h3>
    <p v-if="data.groupByline" class="group-byline">{{data.groupByline}}</p>
    
    <div class="accordion-wrapper">
      <accordion-item v-for="(item, index) in data.items">
        <template v-slot:title>
          <p class="strong">{{item.itemTitle}}</p>
        </template>
        <template v-slot:content>
          <div class="wysiwyg" v-html="item.itemContent"></div>
        </template>
      </accordion-item>
    </div>
  </div>
</template>


<script>
  import AccordionItem from "~/components/AccordionItem.vue";

  export default {
    components : { AccordionItem },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
      }
    },
  }
</script>