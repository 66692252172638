<template>
  <div class="introduction">
    <h2>{{data[0].introductionTitle}}</h2>
    <div v-if="data[0].introductionByline" class="wysiwyg" v-html="data[0].introductionByline"></div>
  </div>
</template>


<script>

  export default {
    props: {
      data: {
        type: Array,
        required: true
      }
    }
  }
</script>