<template>
  <div v-if="data" id="contact-form" class="grid">
    <div class="col intro grid gap-10">
      <h4>Contact</h4>
      <p v-if="data.formByline" class="small">{{data.formByline}}</p>
      <router-link v-if="$route.name !== 'contactIndex'" :to="{name: 'contactIndex'}">
        For general enquiries please visit our contact page 
        <inline-svg :src="$getImageSrc('arrow-long.svg')"></inline-svg>
      </router-link>
    </div>
    <form v-on:submit.prevent="submit" class="col grid sm-2" :class="state" >
      <input type="text" name="name" v-model="name" placeholder="Name*">
      <input type="text" name="phone" v-model="phone" placeholder="Phone">
      <input type="text" name="email" v-model="email" placeholder="Email*">
      <input type="text" name="organisation" v-model="organisation" placeholder="Organisation">
      <textarea v-model="message" name="message" placeholder="Message*" class="col sm-col-2"></textarea>
      <input type="submit" :value="submitValue" :disabled="state" class="col sm-col-2">
      <ul class="validation col sm-col-2 p-small" v-if="errors">
        <li v-if="errors.length > 1">Errors.</li>
        <li v-else>Errors.</li>
        <li v-for="error,index in errors">{{ getErrorMessage(index) }}</li>
      </ul>
    </form>
  </div>
</template>


<script>
  export default {
    props: {
      endpoint: {
        type: String,
        default: 'https://demoresearch.lincoln.ac.nz/wheelform/message/send'
      },
      mode: {
        type: String,
        default: 'general'
      },
      extra: {
          type: Object,
          default() {
              return {}
          }
      },
      data: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
        name: null,
        phone: null,
        email: null,
        organisation: null,
        message: null,
        errors: false,
        state: null,
        csrfTokenName:null,
        csrfToken:null,
      }
    },
    computed: {
      submitValue() {
        if (this.state === 'processing') {
            return 'Processing...'
        } else if (this.state === 'success') {
            return 'Thanks, We\'ll be in touch!'
        } else {
            return 'Submit'
        }
      },
      
    },
    methods: {
      fetchCSRF(){
        this.axios.get('/csrf-token').then(res => {
          // console.log(res)
          this.$store.commit('setCSRFTokenName', res.data.csrfTokenName)
          this.$store.commit('setCSRFToken', res.data.csrfToken)
          // console.log('CSRF retrieve...')
          // console.log(res.data)
        }).catch(error => {
          if(error.response.status === 500) this.$router.push({name: 'error', params: {status: error.response.status}})
          // console.log(error)
        });
      },
      getCsrfTokenName(){
        this.data.csrfTokenName = this.$store.state.csrfTokenName
        return this.$store.state.csrfTokenName
      },
      getCsrfToken(){
        this.data.csrfToken = this.$store.state.csrfToken
        return this.$store.state.csrfToken
      },
      getErrorMessage(index){
        return this.errors[index][0]
      },
      submit() {
        // reset 
        this.state = 'processing'
        this.errors = false
        let routeName = this.$route.name
        const params = new URLSearchParams({ foo: 'bar' });
        params.append('form_id', 1);
        params.append('action', '/wheelform/message/send');
        if (this.name !== null)
          params.append('name', this.name);
        else
          params.append('name', '');
        params.append('pageID', this.$store.state.pageID);
        if (this.email !== null)
          params.append('email', this.email);
        else
          params.append('email', '');
        if (this.phone !== null)
          params.append('phone', this.phone);
        else
          params.append('phone', '');
        if (this.organisation !== null)
          params.append('organisation', this.organisation);
        else
          params.append('organisation', '');
        if (this.message !== null)
          params.append('message', this.message);
        else
          params.append('message', '');
        params.append(this.$store.state.csrfTokenName, this.$store.state.csrfToken);
        this.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        var optionAxios = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }

        this.axios.post('/wheelform/message/send', params, optionAxios)
        .then(response => {
          // console.log(response);
           if(response.data.success) {
              this.state = 'success'
            }
            else{
              this.state = null
              this.errors = response.data.errors
            }
        })
        .catch(error => {
            // console.log('debug: ' + error);
            this.errors = ['There was an error submitting your form at this time. Please try again later']
        });
      }
    },
    created(){
      this.fetchCSRF();
    }
  }
</script>
