<template>
  <div id="error404" class="container">
    <div class="grid gap-10">
      <h1 class="d-blue">Sorry!</h1>
      <h2>We can't seem to find the page you're looking for.</h2>
      <hr>
      <h3>Here are some helpful links instead:</h3>
      <ul>
        <li v-for="l1 in menu">
          <router-link :to="{name: l1.key}"><inline-svg :src="$getImageSrc('arrow')"></inline-svg>{{l1.label}}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>

  export default {
    data(){
      return {
        menu: [
          {key: 'home', label: 'Home'}, 
          {key: 'ourResearch', label: 'Our Research'}, 
          {key: 'testingAnalyticalServices', label: 'Testing & Analytical Services'}, 
          {key: 'industry', label: 'Industry & Partnerships'}, 
          {key: 'postgraduateStudy', label: 'Postgraduate Study'}, 
          {key: 'findAResearcherSupervisor', label: 'Find a Researcher/Supervisor'}, 
          {key: 'updatesIndex', label: 'Updates'}, 
          {key: 'eventsIndex', label: 'Events'}, 
          {key: 'contactIndex', label: 'Contact'}, 
        ]
      }
    },
    computed: {
    }
  }
</script>