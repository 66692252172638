<template>
  <div class="accordion-item" @mouseover="hover = true" @mouseleave="hover = false">
    <div class="accordion-title" :class="{'active' : active}" @click="toggle">
      <img :src="src" class="icon">
      <slot name="title"></slot>
    </div>
    <div v-if="active" class="accordion-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>


<script>

  export default {
    data(){
      return {
        active: false,
      }
    },
    computed: {
      src() {
        let target = (this.active) ? 'minus' : 'plus';
        return this.$getImageSrc(target)
      },
    },
  	methods: {
      toggle() {
        this.active = !this.active
      }
    }
  }
</script>