<template>
  <GmapMap
    ref="gMap"
    :center="position"
    :zoom="zoom"
    :options="{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      styles: styles,
    }"
    style="width: 100%; height: 100%"
  >
    <GmapMarker 
      v-if="markers"
      v-for="(marker, i) in markers" 
      :position="marker.position" 
      :clickable="true"
      @cick="center=marker.position"
      :icon="markerIcon"
      :key="i" />
  </GmapMap>
</template>

<script>

import { gmapApi } from "vue2-google-maps";

export default {
  data() {
    return {
      markerIcon: {
        url: require('/assets/img/pin.png'),
        scaledSize: {
          width: 16, 
          height: 16, 
          f: 'px', 
          b: 'px',
        },
      },
      styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ]
    }
  },
  props: {
    position: {
      type: Object,
      default: function() {
        return { lat: 40.9006, lng: 174.886 };
      }
    },
    zoom: {
      type: Number,
      default: 9
    },
    markers: {
      type: Array,
      default: []
    },
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    setMapView() {
      let self = this;
      this.$refs.gMap.$mapPromise.then(map => {
        //create empty LatLngBounds object
        var bounds = new google.maps.LatLngBounds();

        for (let i = 0; i < self.markers.length; i++) {
          //extend the bounds to include each marker's position
          bounds.extend(self.markers[i].position);
        }

        //now fit the map to the newly inclusive bounds
        map.fitBounds(bounds);
        self.bounds = bounds;

        let listener = google.maps.event.addListener(map, "idle", function() {
          let curZoom = map.getZoom()
          if(curZoom > self.zoom){
            map.setZoom(self.zoom);
          } else{
            map.setZoom(curZoom-1)
          }
          google.maps.event.removeListener(listener);
        });

        if(self.placesQuery) self.placesSearch();
      });
    }
  },
  mounted() {
    this.setMapView();
  },
};
</script>