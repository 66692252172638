<template>
  <div id="related-content" class="container grid">

    <div class="col sm-col-2 grid sm-3 gap-10">
      <router-link v-for="link,index in linksToDisplay" :to="$buildInternalLink(link)">
        <p class="vv-small strong">{{getContainerTitle(index)}}</p>
        <h4 class="heading">{{getPageTitle(index)}}</h4>
        <img src="/assets/img/arrow-long.svg">
      </router-link>

      <router-link v-if="data.showRelatedUpdates" :to="{name: 'updatesIndex' }">
        <p class="vv-small strong">Updates</p>
        <h4 class="heading">Click to view Updates</h4>
        <img src="/assets/img/arrow-long.svg">
      </router-link>

<!--        <router-link v-if="data.showRelatedEvents" :to="{name: 'eventsIndex' }">
        <p class="vv-small strong">Events</p>
        <h4 class="heading">Click to view Events</h4>
        <img src="/assets/img/arrow-long.svg">
      </router-link> -->

     <!-- ******* not sure on this one, we need a means of querying API data to check ***** -->
<!--      <router-link v-if="data.publications" :to="{name: 'publications', query: { filters: parseQuery(data.publications) }}">
       <p class="vv-small strong">Publications</p>
       <h4 class="heading">Click to view all related publications</h4>
       <img src="/assets/img/arrow-long.svg">
     </router-link> -->
     <!-- ********** -->

    </div>
  </div>
</template>


<script>

  export default {
    props: {
      data: {
        type: Object,
        required: true, 
      }
    },
    data(){
      return {
      }
    },
    computed: {
      linksToDisplay(){
        let total = this.checkMaxLinks()
        return this.data.relatedLinks.slice(0, total);
      }
    },
    methods: {
      parseQuery(query) {
        if(Array.isArray(query)) {
          return query.join()
        }
        if(typeof query === 'string') {
          return query
        }
      },
      checkMaxLinks(){
        let maxLinks = 6
        if (this.data.showRelatedEvents)
          maxLinks--
        if (this.data.showRelatedUpdates)
          maxLinks--
        return maxLinks
      },
      checkL1(index){
        if (this.data[index].sectionHandle.includes('Index'))
          return false;
        else
          return true;
      },
      getContainerTitle(index){
        switch(this.data.relatedLinks[index].sectionHandle){
          case 'updatesIndex':
            return 'Updates'
          break;
          case 'updates':
            return 'Update'
          break;
          case 'eventsIndex':
            return 'Events'
          break;
          case 'events':
            return 'Event'
          break;
          case 'studentProfilesChildren':
            return 'Student Profiles'
          break;
          case 'caseStudies':
            return 'Case Studies'
          break;
          case 'faculties':
            return 'Faculties & Research Centres'
          break;
          case 'industryChildren':
            return 'Industry & Partnerships'
          break;
          case 'ourResearchChildren':
            return 'Our Research'
          break;
          case 'postgraduateStudyChildren':
            return 'Post Graduate Study'
          break;
          case 'testingAnalyticalServicesChildren':
            return 'Testing & Analytical Services'
          break;
        }
      },
      getPageTitle(index){
        if (this.data.relatedLinks[index].sectionHandle == 'studentProfilesChildren'){
          let nameParts = this.data.relatedLinks[index].title.split(',')
          if (nameParts.length>1)
            return nameParts[1] + ' ' + nameParts[0]
          else
            return this.data.relatedLinks[index].title
        }
        else
          return this.data.relatedLinks[index].title
      }
    }
  }
</script>