<template>
  <content-wrapper id="fine-print" :class="data.slug" class="small-gap" v-if="data.result">
    
    <template v-slot:header>
      <introduction :data="data.entry"></introduction>
    </template>

    <!-- start dynamic component ordering -->
    <template v-for="component in orderableComponents">
      <component v-for="componentItem in component" :is="$toKebab(componentItem.__typename)" :data="componentItem"></component>
    </template>

    <template v-slot:footer>
      <contact-form v-if="checkForm" :data="data.entry.contactForm[0]"></contact-form>
    </template>

  </content-wrapper>
</template>

<script>
  import ContentWrapper from '~/components/ContentWrapper.vue'
  import Introduction from '~/components/Introduction.vue'
  import Wysiwyg from '~/components/Wysiwyg.vue'
  import ContactForm from '~/components/ContactForm.vue'
  import gql from 'graphql-tag'

  export default {
    components: { ContentWrapper, Introduction, Wysiwyg, ContactForm },
    data(){
      return {
        data: {
          result: false,
          slug: this.$route.name,
          entry: Object,
          currentEntry: 'privacyPolicy'
        }
      }
    },
    apollo: {
      entry: {
        query: gql`
            query variables($section: [String!]) {
              entry(section: $section) {
                id
                title
                ... on privacyPolicy_privacyPolicy_Entry {
                  introductionModuleL2 {
                    ... on introductionModuleL2_introductionL2_BlockType {
                      introductionTitleL2
                      introductionBylineL2
                    }
                  }
                  contentModules {
                    ... on contentModules_Wysiwyg_BlockType {
                      wysiwygContent
                    },
                  },
                  contactForm{
                    ...on contactForm_ContactForm_BlockType{
                      id
                      formByline
                    }
                  }
                }
                ... on disclaimer_disclaimer_Entry {
                  introductionModuleL2 {
                    ... on introductionModuleL2_introductionL2_BlockType {
                      introductionTitleL2
                      introductionBylineL2
                    }
                  }
                  contentModules {
                    ... on contentModules_Wysiwyg_BlockType {
                      wysiwygContent
                    },
                  },
                  contactForm{
                    ...on contactForm_ContactForm_BlockType{
                      id
                      formByline
                    }
                  }
                }
              }
            }
          `,
          variables() {
            let currentSection = this.data.slug
            if (currentSection == 'privacy-policy')
              currentSection = 'privacyPolicy'
            return {
              section: currentSection
            }
          },
          result ({ data, loading, networkStatus }) {
            this.$store.commit('setPageID',data.entry.id)
            this.$store.commit('setTitle',data.entry.title)
            this.data.entry = data.entry
            this.$store.commit('setLoaded',true)
            this.data.result = true
            // output full graphQL result for debug
            // console.log('Full graphQL entry data:')
            // console.log(data.entry)
          }
        }
    },
    computed: {
      orderableComponents() {
        // slighty more refined - have an approach now thats tidy and consistent
        let contentModuleSet = [];
        if (typeof(this.data.entry.contentModules) !== 'undefined'){
          this.data.entry.contentModules.forEach(contentModule=>{
            
           // console.log('content module __typename: ' + contentModule.__typename)

            if (typeof(contentModule.wysiwygContent) !== 'undefined'){
              let actualModule = 'Wysiwyg'
              contentModule.__typename = actualModule
            }
           
          })
          // console.log('Organised orderable components:')
          // console.log(contentModuleSet)

          // console.log('Converted __typenames approach')
          // console.log(this.data.entry.contentModules)

          return [this.data.entry.contentModules];
        }
        else
          return [];
      },
      checkLoaded(){
        return this.data.result
      },
      checkForm(){
        return this.data.entry.contactForm.length
      }
    },
  }
</script>